import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

// const convertToStringAndTitleCase = (inputValue) => {
//   // Split the input string by newline and carriage return characters
//   var parts = inputValue.split("\\r\\n");
//   // Convert each part to title case
//   var titleCasedParts = parts.map(function (part) {
//     return part
//       .toLowerCase()
//       .split(" ")
//       .map(function (word) {
//         return word.charAt(0).toUpperCase() + word.slice(1);
//       })
//       .join(" ");
//   });
//   // Join the title-cased parts into a single string separated by commas
//   var result = titleCasedParts.join(", ");
//   return result;
// };

const AnalysisDetails = ({ analysisDetails }) => {
  const analysisData = [
    {
      label: "Chief Complaints",
      value: analysisDetails?.chief_complaints || "",
    },
    {
      label: "History of Illness",
      value: analysisDetails?.history_of_illness || "",
    },
    {
      label: "Allergy Details",
      value: analysisDetails?.allergy_details || "",
    },
    {
      label: "Physical Examination",
      value: analysisDetails?.physical_examination || "",
    },
    {
      label: "Provisional Diagnosis",
      value: analysisDetails?.provisional_diagnosis || "",
    },
    {
      label: "Investigations Advised",
      value: analysisDetails?.investigations_advised || "",
    },
    {
      label: "Previous Medicines",
      value: analysisDetails?.previous_medicines || "",
    },
  ];

  return (
    <View style={styles.container}>
      <Text style={styles.sectionTitle}>Analysis Details</Text>
      {analysisData.map(({ label, value }) => (
        <View style={styles.row} key={label}>
          <Text style={styles.label}>{label}:</Text>
          <Text style={styles.value}>{value}</Text>
        </View>
      ))}
    </View>
  );
};

export default AnalysisDetails;

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  sectionTitle: {
    fontFamily: "Lato Bold",
    fontSize: 15,
    marginBottom: 7,
    textAlign: "center",
    textDecoration: "underline",
  },
  row: {
    flexDirection: "row",
    // alignItems: "baseline",
    marginBottom: 8,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  label: {
    fontFamily: "Lato Bold",
    fontSize: 12,
    width: "25%",
    paddingRight: 5,
    color: "black",
  },
  value: {
    fontFamily: "Lato",
    fontSize: 12,
    width: "70%",
  },

  // NOTE: Removing dotted lines (for blank space)
  // dottedLine: {
  //   borderBottomWidth: 1,
  //   // borderBottomColor: "#000",
  //   // borderBottomStyle: "dotted",
  //   fontSize: 12,
  //   width: "70%",
  //   flexGrow: 1,
  //   marginHorizontal: 5,
  // },
});
