import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import { BiMenuAltLeft } from "react-icons/bi";
import logo from "../assets/images/logo_without_bg.png";
import AuthContext from "../context/AuthContext";
import { Box } from "@mui/material";

const Navbar = () => {
  const { authTokens, logoutUser } = useContext(AuthContext);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header>
      <nav className="navbar">
        <NavLink to="/" className="logo">
          <img src={logo} alt="Denama" className="brand-logo" />
          Denama
        </NavLink>
        <div className={`nav-links ${isMobileMenuOpen ? "mobile-menu" : ""}`}>
          <ul className="nav-menu">
            {authTokens && (
              <>
                <li>
                  <NavLink to="/dashboard" className="nav-link" end>
                    Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/patient" className="nav-link">
                    Patient
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/medicine-suggestions" className="nav-link">
                    Medicine
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/notes" className="nav-link">
                    Notes
                  </NavLink>
                </li>
                <li>
                  <Box
                    onClick={logoutUser}
                    sx={{
                      color: "#bb86fc",
                      transition: "color 0.3s ease",
                      "&:hover": {
                        color: "#cab8ff",
                      },
                    }}
                  >
                    Logout
                  </Box>
                </li>
              </>
            )}
          </ul>
        </div>
        <BiMenuAltLeft
          className="menu-hamburger"
          onClick={toggleMobileMenu}
          aria-expanded={isMobileMenuOpen ? "true" : "false"}
        />
      </nav>
    </header>
  );
};

export default Navbar;
