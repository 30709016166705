import React, { useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import AuthContext from "../context/AuthContext";
import MuiAlert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SimpleSnackbar = () => {
  const { notification, setNotification } = useContext(AuthContext);
  const { isOpen, message, severity } = notification;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({
      isOpen: false,
      message: message,
      severity: severity,
    });
  };

  return (
    <div>
      <Snackbar
        open={isOpen}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: isMobile ? "top" : "bottom",
          horizontal: isMobile ? "center" : "left",
        }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{
            width: isMobile ? "100%" : "auto",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SimpleSnackbar;
