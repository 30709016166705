import React from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./EditorStyles.css";

export const Editor = ({ dataForCreatingTicket, setDataForCreatingTicket }) => {
  return (
    <div className="text-editor">
      <EditorToolbar />
      <ReactQuill
        theme="snow"
        value={dataForCreatingTicket.description}
        onChange={(value) =>
          setDataForCreatingTicket((prev) => ({ ...prev, description: value }))
        }
        placeholder={"Description..."}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default Editor;
