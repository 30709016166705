import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box } from "@mui/material";
import { darkThemeStyles } from "../../styledComponents/CustomStyleComponents";

const DashboardCard = ({ styleOfDashboard, title, bgIcon, quantity }) => {
  return (
    <Card
      sx={{
        ...styleOfDashboard,
        boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
        borderRadius: "10px",
      }}
    >
      <CardContent sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box fontSize="28px" color={darkThemeStyles.onSurface}>
            {title}
          </Box>
          <Box
            fontSize="50px"
            margin="0px 0 10px 20px"
            color={darkThemeStyles.onSurface}
          >
            {quantity}
          </Box>
        </Box>
        <div className="dashboard-card-icon">{bgIcon}</div>
      </CardContent>
    </Card>
  );
};
export default DashboardCard;
