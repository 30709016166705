import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import {
  CardHeader,
  CardMedia,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDropzone } from "react-dropzone";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import Editor from "./Editor";
import AuthContext from "../../context/AuthContext";
import { saveButtonStyle } from "../../styledComponents/CustomStyleComponents";

const styleOfModal = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "800px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  padding: "15px 5px",
};

const CreateTicketModal = ({ open, setOpen }) => {
  const { supportTicketPostAPI, fetchSupportTicketsData } =
    useContext(AuthContext);
  const [filesUploaded, setFilesUploaded] = useState([]);
  const [dataForCreatingTicket, setDataForCreatingTicket] = useState({
    summary: "",
    description: "",
    status: "To do",
    priority: "Medium",
    file_uploads: "",
  });

  const handleClose = () => {
    setOpen(false);
    setDataForCreatingTicket({
      summary: "",
      description: "",
      status: "To do",
      priority: "Medium",
      file_uploads: "",
    });
    setFilesUploaded([]);
  };

  // for drag & drop files
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      setFilesUploaded((prevFiles) => [...prevFiles, ...newFiles]);
      setDataForCreatingTicket((prev) => ({
        ...prev,
        file_uploads: [...prev.file_uploads, ...acceptedFiles],
      }));
    },
  });

  const removeFile = (indexToRemove) => {
    setFilesUploaded((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );

    setDataForCreatingTicket((prev) => ({
      ...prev,
      file_uploads: prev.file_uploads.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  const FilePreview = ({ file, index }) => {
    return (
      <div
        style={{
          position: "relative",
          width: 125,
          margin: "10px 0 0 0",
        }}
      >
        <Card variant="outlined">
          <CardMedia component="img" height="50" image={file.preview} alt="" />
          <CardHeader
            title={file.name}
            titleTypographyProps={{
              noWrap: true,
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: 12,
            }}
            sx={{ padding: "16px 5px" }}
          />

          <button
            style={{
              position: "absolute",
              top: -5,
              right: -10,
              backgroundColor: "#635e5ea6",
              color: "white",
              border: "none",
              borderRadius: "50px",
              cursor: "pointer",
              width: "25px",
            }}
            onClick={() => removeFile(index)}
          >
            X
          </button>
        </Card>
      </div>
    );
  };
  const changeHandler = (e) => {
    let { value, name } = e.target;
    setDataForCreatingTicket((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTicketSubmit = async () => {
    let isSuccess = await supportTicketPostAPI(dataForCreatingTicket);
    if (isSuccess) {
      fetchSupportTicketsData();
      handleClose();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-create-ticket"
        aria-describedby="modal-create-ticket-desc"
      >
        <>
          <Card sx={styleOfModal}>
            <IconButton
              sx={{ right: 15, position: "absolute", color: "black" }}
              onClick={handleClose}
            >
              <ClearOutlinedIcon />
            </IconButton>
            <CardHeader
              title="Create Issue"
              sx={{
                padding: "10px 16px",
              }}
            />
            <CardContent
              sx={{ padding: "16px", overflowY: "auto", maxHeight: "80vh" }}
            >
              <Box mb={2}>
                <Box mb="8px">
                  Summary <span style={{ color: "red" }}> *</span>
                </Box>
                <TextField
                  variant="filled"
                  fullWidth
                  sx={{
                    "& .MuiInputBase-input.MuiFilledInput-input": {
                      padding: "10px 12px",
                    },
                  }}
                  name="summary"
                  value={dataForCreatingTicket?.summary || ""}
                  onChange={changeHandler}
                />
              </Box>

              <Box mb={2}>
                <Box mb="8px">
                  Description <span style={{ color: "red" }}> *</span>
                </Box>
                <Editor
                  dataForCreatingTicket={dataForCreatingTicket}
                  setDataForCreatingTicket={setDataForCreatingTicket}
                />
              </Box>
              <Box mb={2}>
                <Box mb="8px">Attachment</Box>
                <Box
                  {...getRootProps()}
                  style={{
                    border: "0.1px dashed #8080809c",
                    padding: "10px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <input {...getInputProps()} />
                  <Typography>
                    Drag & drop files to attach or
                    <span style={{ color: "blue" }}> browse</span>
                  </Typography>
                </Box>
                <Grid container spacing={1}>
                  {filesUploaded.map((file, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={file.path}>
                      <FilePreview file={file} index={index} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box mb={2}>
                <Box>Priority</Box>
                <FormControl
                  variant="filled"
                  sx={{ m: 0, minWidth: 120, paddingTop: "8px" }}
                >
                  <Select
                    name="priority"
                    value={dataForCreatingTicket.priority}
                    onChange={changeHandler}
                    sx={{
                      "& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input":
                        {
                          padding: "10px 12px",
                        },
                    }}
                  >
                    <MenuItem value="Highest">Highest</MenuItem>
                    <MenuItem value="High">High</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="Low">Low</MenuItem>
                    <MenuItem value="Lowest">Lowest</MenuItem>
                  </Select>
                  <FormHelperText sx={{ marginLeft: 0 }}>
                    This is the issue's initial status upon creation
                  </FormHelperText>
                </FormControl>
              </Box>
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end", padding: "0 8px" }}>
              <Button
                size="small"
                variant="contained"
                onClick={handleTicketSubmit}
                sx={saveButtonStyle}
              >
                Create
              </Button>
            </CardActions>
          </Card>
        </>
      </Modal>
    </>
  );
};

export default CreateTicketModal;
