import React from "react";
import logo from "../../assets/images/logo_without_bg.png";
import "./Footer.css";
import instagramSvg from "../../assets/icons/instagram.svg";
import linkedinSvg from "../../assets/icons/linkedin.svg";
import twitterSvg from "../../assets/icons/twitter.svg";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer>
      <div className="footer-line"></div>
      <div className="footer-wrapper">
        <div className="footer-columns">
          <Link to="/">
            <section className="footer-logo">
              <img src={logo} alt="Denama" height={45} width="auto" />
              <div className="footer-logo-text">Denama</div>
            </section>
          </Link>

          <section className="footer-about-company">
            <div className="footer-about">About the company</div>
            <div className="content">
              Innovating for Better Care: Our state-of-the-art platform provides
              healthcare professionals the leverage they need by optimizing
              patient data handling and medication management workflows. Embrace
              efficiency and dedicate more time to what's paramount — exceptional
              patient care.
            </div>
          </section>
        </div>
        <div className="footer-bottom">
          <small>
            Denama © {new Date().getFullYear()}, All rights reserved
          </small>
          <span className="social-links">
            <img src={instagramSvg} alt="Instagram" />
            <img src={linkedinSvg} alt="Linkedin" />
            <img src={twitterSvg} alt="Twitter" />
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
