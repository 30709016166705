import React, { useContext, useEffect } from "react";
import "./Patient.css";
import PatientActions from "./PatientActions";
import PatientSearch from "./PatientSearch";
import PatientInfo from "./PatientInfo";
import TreatmentAndRecommendations from "./TreatmentAndRecommendations";
import PatientAnalysis from "./PatientAnalysis";
import Navbar from "../../Layout/Navbar";
import VisitHistory from "./VisitHistory";
import AuthContext from "../../context/AuthContext";
import { Helmet } from "react-helmet";

const Patient = () => {
  const { fetchUserInfo, userInfo } = useContext(AuthContext);

  useEffect(() => {
    if (!userInfo) {
      fetchUserInfo();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Denama | Patient Information</title>
      </Helmet>
      <div className="patient-panel-container">
        <Navbar />
        <div
          className="patient-top-section"
          style={{ borderLeft: "1.5px solid white" }}
        >
          <div className="pt-left-section">
            <PatientActions />
            <PatientSearch />
          </div>

          <div className="pt-center-section">
            <PatientInfo />
          </div>

          <div className="pt-right-section">
            <VisitHistory />
          </div>
        </div>

        <div className="patient-middle-section">
          <PatientAnalysis />
        </div>

        <div className="patient-bottom-section">
          <TreatmentAndRecommendations />
        </div>
      </div>
    </>
  );
};

export default Patient;
