import * as React from "react";
import Box from "@mui/material/Box";

import logo from "../../../assets/images/logo_without_bg.png";
import RegisterStepsCard from "./RegisterStepsCard";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const RegisterPage = () => {
  return (
    <>
      <Helmet>
        <title>Denama | Create Account</title>
      </Helmet>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          bgcolor: "#ccdfec",
        }}
      >
        <Box
          py="15px"
          sx={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <Link to="/">
            <Box component="span">
              <img src={logo} alt="" width="70px" height="auto" />
            </Box>
            <Box
              component="span"
              sx={{
                fontSize: "30px",
                fontWeight: 600,
                color: "#0C356A",
                "@media (max-width:360px)": {
                  fontSize: "25px",
                },
              }}
            >
              Denama
            </Box>
          </Link>
        </Box>
        <RegisterStepsCard />
        <Box
          color="rgb(70 97 133)"
          paddingTop="20px"
          sx={{
            "@media (max-width:700px)": {
              fontSize: "15px",
            },
          }}
        >
          Already have an account? &nbsp;
          <Box
            component="span"
            color="black"
            sx={{
              "&:hover": { textDecoration: "underline" },
              "@media (max-width:700px)": {
                fontSize: "15px",
              },
            }}
          >
            <Link to="/login">Log in</Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default RegisterPage;
