export const getCurrentDateFormatted = () => {
  const date = new Date();

  const year = date.getFullYear();

  // getMonth() returns a zero-based month, so add 1. Ensure it's two digits.
  const month = String(date.getMonth() + 1).padStart(2, "0");

  // Ensure day is two digits.
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const INITIAL_FIELDS_OBJ_OF_REGISTER_PAGE = {
  first_name: "",
  last_name: "",
  username: "",
  password: "",
  confirmPassword: "",
  email: "",
  gender: "",
  date_of_birth: new Date().toISOString().split("T")[0],
  contact_timings: "",
  official_contact_number: "",
  personal_contact_number: "",
  qualification: "",
  additional_qualification: "",
  residence_address: "",
  clinic_address: "",
  registration_number: "",
};
