import React from "react";
import { Route, Routes } from "react-router-dom";
import privateRoutes from "./routes/PrivateRoutes";
import publicRoutes from "./routes/PublicRoutes";
import SimpleSnackbar from "./helpers/SnackBar";
import PrivateRoute from "./utils/PrivateRoute";
import ErrorPage from "./pages/404 page/ErrorPage";
import { Helmet } from "react-helmet";
const App = () => {
  return (
    <>
      <Helmet>
        <title>Denama: Streamlining Patient Management for Doctors</title>
      </Helmet>
      <Routes>
        {publicRoutes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        {privateRoutes.map(({ path, element, children }) => (
          <Route
            key={path}
            path={path}
            element={<PrivateRoute>{element}</PrivateRoute>}
          >
            {children &&
              children.map(({ path, element }) => (
                <Route
                  key={path}
                  path={path}
                  element={<PrivateRoute>{element}</PrivateRoute>}
                />
              ))}
          </Route>
        ))}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <SimpleSnackbar />
    </>
  );
};

export default App;
