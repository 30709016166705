import ForgotPassword from "../pages/accounts/forgotPassword/ForgotPassword";
import LoginPage from "../pages/accounts/login/LoginPage";
import RegisterPage from "../pages/accounts/register/RegisterPage";
import HomePage from "../pages/homescreen/HomePage";

const publicRoutes = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/create-account",
    element: <RegisterPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/",
    element: <HomePage />,
  },
];

export default publicRoutes;
