import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import AuthContext from "../../context/AuthContext";
import SearchIcon from "@mui/icons-material/Search";
import {
  CustomTextField,
  stylesForSelectComponent,
  stylesForSelectProps,
} from "../../styledComponents/CustomStyleComponents";
import { debounce } from "lodash";
import SearchResultsTable from "./SearchResultsTable";

const optionsForPatientSearch = [
  {
    name: "name",
    label: "Name",
  },
  {
    name: "address",
    label: "Address",
  },
  {
    name: "mobile",
    label: "Mobile",
  },
];

const PatientSearch = () => {
  const {
    patientSearchResults,
    setPatientSearchResults,
    fetchPatientDataAfterSearch,
    searchInputValue,
    setSearchInputValue,
  } = useContext(AuthContext);

  const [searchField, setSearchField] = useState(
    optionsForPatientSearch[0]["name"]
  );

  const selectMenuChangeHandler = (e) => {
    setSearchField(e.target.value);
    setSearchInputValue("");
  };

  // Debouncing ensures that the API call is made after a certain amount of time since the user stopped typing.
  // Debounced API call for suggestions
  const fetchSearchResult = debounce(fetchPatientDataAfterSearch, 300);

  useEffect(() => {
    if (searchInputValue.length > 1) {
      fetchSearchResult(searchField, searchInputValue);
    } else {
      setPatientSearchResults([]);
    }

    // Clearing the debounce on component unmount
    return () => {
      fetchSearchResult.cancel();
    };
  }, [searchInputValue]);

  return (
    <section className="bg-patient">
      <div className="p-heading">Search</div>
      <Box>
        <Grid container spacing={1} alignItems={"center"} padding={1}>
          <Grid item xs>
            <TextField
              select
              value={searchField}
              variant="outlined"
              fullWidth
              onChange={selectMenuChangeHandler}
              sx={{
                ...stylesForSelectComponent,
                "& .MuiInputBase-input": {
                  padding: "7px 14px",
                },
              }}
              SelectProps={stylesForSelectProps}
            >
              {optionsForPatientSearch.map(({ name, label }, index) => (
                <MenuItem value={name} key={index}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs>
            <CustomTextField
              fullWidth
              variant="outlined"
              type="text"
              value={searchInputValue}
              name="searchInputValue"
              onChange={(e) => {
                setSearchInputValue(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="search"
                      edge="end"
                      type="submit"
                      sx={{ color: "#E0E0E0" }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "7px 14px",
                },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#292929",
                  borderRadius: 0,
                },
              }}
              placeholder={"Type Patient's " + searchField}
            />
          </Grid>
        </Grid>

        <Box padding="0 5px">
          <SearchResultsTable
            searchResults={patientSearchResults || []}
            searchField={searchField}
          />
        </Box>
      </Box>
    </section>
  );
};

export default PatientSearch;
