import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  INITIAL_PATIENT_ANALYSIS_OBJ,
  INITIAL_PATIENT_INFO_OBJ,
} from "../../context/contextUtils";
import AuthContext from "../../context/AuthContext";

const PatientDeleteAlertDialog = ({ open, setOpen }) => {
  const {
    setPatientInfo,
    setPatientAnalysis,
    setSelectedPatientRowId,
    setSelectedPatientAndAllVisitsData,
    setSelectedVisitAnalysisAndMedicineData,
    setMediList,
    selectedPatientRowId,
    setIsrevisitBtnClicked,
    patientDeleteAPI,
    setPatientSearchResults,
    setSearchInputValue,
  } = useContext(AuthContext);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAndDeleteConfirm = async () => {
    setOpen(false);

    await patientDeleteAPI(selectedPatientRowId);
    setPatientAnalysis(INITIAL_PATIENT_ANALYSIS_OBJ);
    setPatientInfo(INITIAL_PATIENT_INFO_OBJ);
    setSelectedPatientRowId("");
    setMediList([
      {
        med_name: "",
        med_schedule: "",
        med_instructions: "",
        med_route: "",
        number_of_days: 0,
      },
    ]);
    setSelectedPatientAndAllVisitsData(null);
    setSelectedVisitAnalysisAndMedicineData(null);
    setIsrevisitBtnClicked(false);
    setPatientSearchResults(null);
    setSearchInputValue("");
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Patient Data Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm if you wish to permanently delete the record. This
            action will remove all associated data, including visit history,
            diagnoses, and treatment plans.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleCloseAndDeleteConfirm}
            autoFocus
            variant="contained"
            sx={{
              backgroundColor: "#0066cc",
              "&:hover": {
                backgroundColor: "#004d99",
              },
              borderRadius: "50px",
            }}
          >
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default PatientDeleteAlertDialog;
