import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import { Button, IconButton, Tooltip } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { darkThemeStyles } from "../../styledComponents/CustomStyleComponents";

// Function to parse the date string into a Date object
const parseDate = (str) => {
  let parts = str.match(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/);
  return new Date(
    parts[3],
    parts[2] - 1,
    parts[1],
    parts[4],
    parts[5],
    parts[6]
  );
};

const VisitHistory = () => {
  const {
    selectedPatientAndAllVisitsData,
    setSelectedVisitAnalysisAndMedicineData,
    selectedVisitAnalysisAndMedicineData,
    setIsrevisitBtnClicked,
  } = useContext(AuthContext);

  const [visitDetailArray, setVisitDetailArray] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc"); // 'asc' is the default sort order

  // Function to toggle the sort order
  const toggleSortOrder = () => {
    setSortOrder((prevSortOrder) => (prevSortOrder === "asc" ? "desc" : "asc"));
  };

  useEffect(() => {
    let visits = selectedPatientAndAllVisitsData?.visits;
    if (visits) {
      const sortedVisits = [...visits].sort((a, b) => {
        let dateA = parseDate(a.visit_details.visit_date);
        let dateB = parseDate(b.visit_details.visit_date);
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      });
      setVisitDetailArray(sortedVisits);
    } else {
      setVisitDetailArray([]);
    }
  }, [selectedPatientAndAllVisitsData, sortOrder]);

  useEffect(() => {
    if (visitDetailArray) {
      setSelectedVisitAnalysisAndMedicineData(visitDetailArray[0]);
    } else {
      setSelectedVisitAnalysisAndMedicineData(null);
    }
  }, [visitDetailArray]);

  const visitSelectHandler = (value) => {
    setSelectedVisitAnalysisAndMedicineData(value);
    setIsrevisitBtnClicked(false);
  };

  return (
    <section
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        className="p-heading"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <span>Visit History</span>
        <Tooltip title="Sort" arrow placement="top">
          <IconButton
            onClick={toggleSortOrder}
            aria-label={`Sort by date ${sortOrder}`}
            sx={{ color: "white" }}
          >
            {sortOrder === "asc" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </IconButton>
        </Tooltip>
      </div>
      <div
        style={{
          overflow: "auto",
          padding: "3px",
          height: "274px",
        }}
      >
        {visitDetailArray.map((value, index) => (
          <Button
            aria-label={`date_${index}`}
            key={index}
            variant="contained"
            fullWidth
            onClick={visitSelectHandler.bind(null, value)}
            sx={{
              color:
                value.visit_details.visit_date ===
                selectedVisitAnalysisAndMedicineData?.visit_details.visit_date
                  ? darkThemeStyles.onPrimary
                  : "#FFFFFF",
              backgroundColor:
                value.visit_details.visit_date ===
                selectedVisitAnalysisAndMedicineData?.visit_details.visit_date
                  ? darkThemeStyles.secondary
                  : darkThemeStyles.surface,
              "&:hover": {
                background:
                  value.visit_details.visit_date ===
                  selectedVisitAnalysisAndMedicineData?.visit_details.visit_date
                    ? "rgb(3 218 198 / 90%)"
                    : "rgba(255, 255, 255, 0.28)",
              },
              border: "1px solid rgba(255, 255, 255, 0.1)",
              mb: 0.5,
            }}
          >
            {value.visit_details.visit_date}
          </Button>
        ))}
      </div>
    </section>
  );
};

export default VisitHistory;
