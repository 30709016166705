import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { convertToTitleCaseWithSpace } from "../../context/contextUtils";

const styles = StyleSheet.create({
  sectionTitle: {
    fontFamily: "Lato Bold",
    fontSize: 15,
    padding: 10,
    textAlign: "center",
    textDecoration: "underline",
  },
});

const MedicinePrescription = ({ medicineData }) => {
  return (
    <View>
      <Text style={styles.sectionTitle}>Medicine Prescription Details </Text>
      <View style={{padding:"0 15px"}}>
        {medicineData?.map((data, index) => (
          <View
            key={index}
            style={{ fontSize: 13, display: "flex", flexDirection: "row" }}
          >
            <Text style={{ marginRight: "6px" }}>{`${index + 1})`}</Text>
            <Text>{data.med_name || ""}</Text>
          </View>
        ))}
      </View>
    </View>
  );
};

export default MedicinePrescription;
