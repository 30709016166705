import React, { useContext, useEffect } from "react";
import AuthContext from "../../context/AuthContext";
import { INITIAL_PATIENT_ANALYSIS_OBJ } from "../../context/contextUtils";
import { Box, Tooltip } from "@mui/material";
import { CustomTextField } from "../../styledComponents/CustomStyleComponents";

const patientAnalysisFormData = [
  {
    name: "Chief Complaints",
    fields: "chiefComplaints",
    required: true,
  },
  {
    name: "Physical Examination",
    fields: "physicalExamination",
    required: false,
  },
  {
    name: "History Of Illness",
    fields: "historyOfIllness",
    required: false,
  },
  {
    name: "Allergy Details",
    fields: "allergyDetails",
    required: false,
  },
  {
    name: "Investigation Advised",
    fields: "investigationAdvised",
    required: false,
  },
  {
    name: "Provisional Diagnosis",
    fields: "provisionalDiagnosis",
    required: false,
  },
  {
    name: "Previous Medicines",
    fields: "previousMedicine",
    required: false,
  },
];

const Section = ({ name, value, fields, onChange, disabled, required }) => (
  <Box
    sx={disabled ? { borderRight: "1.5px solid white", flex: 1 } : { flex: 1 }}
  >
    {required ? (
      <div
        className="p-heading"
        style={
          disabled
            ? {
                borderBottom: "1px solid white",
              }
            : { borderRight: "1px solid white" }
        }
      >
        {name}
        <Tooltip title="Required" placement="top" arrow>
          <span style={{ color: "red" }}>&nbsp;*</span>
        </Tooltip>
      </div>
    ) : (
      <div
        className="p-heading"
        style={
          disabled
            ? { borderBottom: "1px solid white" }
            : { borderRight: "1px solid white" }
        }
      >
        {name}
      </div>
    )}
    <CustomTextField
      id={fields}
      autoComplete="off"
      multiline
      rows={5}
      name={fields}
      onChange={onChange}
      value={value}
      aria-label={fields}
      variant="outlined"
      fullWidth
      InputProps={{
        style: { padding: 5, borderRadius: 0 },
      }}
      disabled={disabled}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: 0,
          backgroundColor: "#292929",
        },
      }}
    />
  </Box>
);

const PatientAnalysis = () => {
  const {
    patientAnalysis,
    setPatientAnalysis,
    selectedVisitAnalysisAndMedicineData,
  } = useContext(AuthContext);

  const handleChange = (e) => {
    if (!selectedVisitAnalysisAndMedicineData) {
      const { name, value } = e.target;
      setPatientAnalysis((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (selectedVisitAnalysisAndMedicineData) {
      let analysisDetails =
        selectedVisitAnalysisAndMedicineData.analysis_details;
      const newData = {
        chiefComplaints: analysisDetails.chief_complaints,
        physicalExamination: analysisDetails.physical_examination,
        historyOfIllness: analysisDetails.history_of_illness,
        allergyDetails: analysisDetails.allergy_details,
        investigationAdvised: analysisDetails.investigations_advised,
        provisionalDiagnosis: analysisDetails.provisional_diagnosis,
        previousMedicine: analysisDetails.previous_medicines,
      };
      setPatientAnalysis(newData);
    } else {
      setPatientAnalysis(INITIAL_PATIENT_ANALYSIS_OBJ);
    }
  }, [selectedVisitAnalysisAndMedicineData]);

  return (
    <Box display="flex" component="section" width="100%">
      {patientAnalysisFormData.map(({ name, fields, required }) => (
        <Section
          key={fields}
          name={name}
          value={patientAnalysis[fields]}
          onChange={handleChange}
          fields={fields}
          disabled={!!selectedVisitAnalysisAndMedicineData}
          required={required}
        />
      ))}
    </Box>
  );
};

export default PatientAnalysis;
