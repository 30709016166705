import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import AuthContext from "../../context/AuthContext";
import {
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { darkThemeStyles } from "../../styledComponents/CustomStyleComponents";
import {
  stylesForSelectComponent,
  stylesForSelectProps,
} from "../../styledComponents/CustomStyleComponents";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import {
  FILTER_DAILY,
  FILTER_MONTHLY,
  FILTER_YEARLY,
  CustomSquareDot,
  mergeArraysBySameKey,
} from "./ChartUtils";

const primaryLineColor = darkThemeStyles.primary; // existing color
const secondaryLineColor = "#82ca9d"; // A distinct green color

const ChartOfNewPatientVisits = () => {
  const {
    getPatientVisitStats,
    getPatientReVisitStats,
    graphStatsData,
    revisitGraphStatsData,
  } = useContext(AuthContext);
  const [filterValue, setFilterValue] = useState(FILTER_DAILY);
  const [dataFetched, setDataFetched] = useState(false); // New state to track data fetch attempts

  useEffect(() => {
    const fetchData = async () => {
      await getPatientVisitStats(filterValue);
      await getPatientReVisitStats(filterValue);
      setDataFetched(true);
    };

    fetchData();
  }, [filterValue]);

  const mergedData = useMemo(() => {
    if (dataFetched && (graphStatsData || revisitGraphStatsData)) {
      return mergeArraysBySameKey(
        graphStatsData,
        revisitGraphStatsData,
        filterValue
      );
    } else {
      return [];
    }
  }, [graphStatsData, revisitGraphStatsData, filterValue, dataFetched]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: darkThemeStyles.background,
          color: darkThemeStyles.onSurface,
          paddingY: "15px",
          px: 3,
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ color: darkThemeStyles.onSurface, marginBottom: 0 }}
        >
          Patient Analytics
        </Typography>

        <Box pr="2rem" alignSelf="flex-end">
          <TextField
            select
            value={filterValue}
            variant="outlined"
            onChange={(e) => {
              setFilterValue(e.target.value);
              setDataFetched(false);
            }}
            sx={{
              ...stylesForSelectComponent,
              width: "auto", // Adjust width if needed
              "& .MuiInputBase-input": {
                padding: "10px 20px", // Increase padding for prominence
                fontSize: "1rem", // Increase font size for better visibility
              },
            }}
            SelectProps={stylesForSelectProps}
          >
            <MenuItem value={FILTER_DAILY}>Last 15 Days</MenuItem>
            <MenuItem value={FILTER_MONTHLY}>Monthly</MenuItem>
            <MenuItem value={FILTER_YEARLY}>Yearly</MenuItem>
          </TextField>
        </Box>

        {/* Loading Animation */}
        {!dataFetched && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 400, // Same height as your graph for consistent layout
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}

        {/* Graph Rendering */}
        {dataFetched && mergedData?.length > 0 ? (
          <>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                data={mergedData}
                margin={{ top: 5, right: 30, left: 20, bottom: 30 }}
              >
                <CartesianGrid
                  strokeDasharray="3 3"
                  stroke={darkThemeStyles.onSurface}
                />
                <XAxis
                  dataKey={
                    filterValue === FILTER_MONTHLY
                      ? "month"
                      : filterValue === FILTER_YEARLY
                      ? "year"
                      : "date"
                  }
                  stroke={darkThemeStyles.onSurface}
                  tickMargin={8}
                  label={{
                    value:
                      filterValue === FILTER_MONTHLY
                        ? "Monthly Visits Of Current Year"
                        : filterValue === FILTER_YEARLY
                        ? "Yearly Visits"
                        : "Last 15 Days Visits",
                    dy: 35, // Moves the label downwards (positive value) from the X-axis
                    style: {
                      fill: darkThemeStyles.onSurface,
                    },
                  }}
                />
                <YAxis
                  stroke={darkThemeStyles.onSurface}
                  label={{
                    value: "Patients Count",
                    angle: -90,
                    dx: -20, // Moves the label 10px to the left
                    style: {
                      fill: darkThemeStyles.onSurface, // This sets the color of the label
                    },
                  }}
                />
                <Tooltip
                  wrapperStyle={{
                    borderColor: darkThemeStyles.primary,
                    backgroundColor: darkThemeStyles.surface,
                    color: darkThemeStyles.onPrimary,
                    padding: "10px",
                  }}
                  cursor={{
                    stroke: darkThemeStyles.onSurface,
                    strokeWidth: 2,
                  }}
                />
                <Legend
                  wrapperStyle={{ color: darkThemeStyles.onSurface }}
                  verticalAlign="top"
                  height={36}
                />
                <Line
                  type="monotone"
                  dataKey="count"
                  stroke={primaryLineColor}
                  strokeWidth={3} // Slightly thicker line for primary data
                  dot={{ stroke: primaryLineColor, strokeWidth: 2, r: 5 }} // Circular dots
                  activeDot={{ r: 8, fill: primaryLineColor }}
                  name="New Patient Count"
                  isAnimationActive={true}
                />

                <Line
                  type="monotone"
                  dataKey="revisit_count"
                  stroke={secondaryLineColor}
                  strokeWidth={2} // Standard stroke width for secondary data
                  dot={<CustomSquareDot />}
                  activeDot={{ r: 8, fill: secondaryLineColor }}
                  name="Revisited Patient Count"
                  isAnimationActive={true}
                />
              </LineChart>
            </ResponsiveContainer>
          </>
        ) : dataFetched ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              textAlign: "center",
              minHeight: 400,
            }}
          >
            <SentimentDissatisfiedIcon
              sx={{ fontSize: 60, color: darkThemeStyles.primary }}
            />
            <Typography
              variant="h6"
              sx={{ color: darkThemeStyles.onSurface, my: 2 }}
            >
              No patient data available for the selected period.
            </Typography>
            <Typography variant="h6" sx={{ color: darkThemeStyles.onSurface }}>
              Try Different Filter
            </Typography>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default ChartOfNewPatientVisits;
