import React, { cloneElement, useContext, useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Avatar, Box, IconButton } from "@mui/material";
import { Link, NavLink, useLocation } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import "./SidebarComponent.css";
import AuthContext from "../context/AuthContext";
import { sidebarElement } from "./SidebarElements";
import { BACKEND_BASE_URL } from "../api/api";
import { darkThemeStyles } from "../styledComponents/CustomStyleComponents";
import { stringToColorForAvatar } from "../context/contextUtils";

const SidebarComponent = () => {
  const { userInfo } = useContext(AuthContext);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const profileImage = BACKEND_BASE_URL + userInfo?.profile_pic;
  const username = `${userInfo?.first_name || ""} ${userInfo?.last_name || ""}`;
  const firstLastCharSplit = `${
    username.split(" ")[0] ? username.split(" ")[0][0] : username.split(" ")[0]
  }${
    username.split(" ")[1] ? username.split(" ")[1][0] : username.split(" ")[1]
  }`;

  // Function to handle window resize
  const handleResize = () => {
    // Check the screen width and set isCollapsed accordingly
    setIsCollapsed(window.innerWidth <= 768);
  };

  // Add a resize event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    // Initialize the isCollapsed state based on the initial window width
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Sidebar
        collapsed={isCollapsed}
        backgroundColor={darkThemeStyles.surface}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "20px",
            paddingLeft: "20px",
          }}
        >
          <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
            <MenuOutlinedIcon sx={{ color: "#BB86FC" }} />
          </IconButton>
        </Box>
        <Menu
          menuItemStyles={{
            button: {
              // TODO: need to check
              // [`&.active`]: {
              //   borderRight: "5px solid #03DAC6",
              //   color: "#BB86FC",
              // },
              "&:hover": {
                backgroundColor: "#2C2C2C",
                color: "#E0E0E0",
              },
              color: "#E0E0E0", // Default non-hover and non-active color
            },
          }}
        >
          <Link to="my-profile">
            <Box my="20px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar
                  sx={{
                    bgcolor: stringToColorForAvatar(username),
                    width: isCollapsed ? "50px" : "125px",
                    height: isCollapsed ? "50px" : "125px",
                    border: "3px solid white",
                  }}
                  src={profileImage}
                >
                  {userInfo?.profile_pic ? "" : firstLastCharSplit}
                </Avatar>
              </Box>
              {!isCollapsed && (
                <Box textAlign="center">
                  <div
                    className="sidebar-username"
                    style={{ color: "#E0E0E0" }}
                  >
                    {username}
                  </div>
                  <div
                    className="sidebar-organisation-name"
                    style={{ color: "#B3B3B3" }}
                  ></div>
                </Box>
              )}
            </Box>
          </Link>

          <Box>
            {sidebarElement.map(({ name, elementPath, icon }) => (
              <MenuItem
                component={<NavLink to={elementPath} />}
                icon={cloneElement(icon, { style: { color: "#BB86FC" } })}
                key={name}
              >
                {name}
              </MenuItem>
            ))}

            {/* <MenuItem
              icon={
                <PowerSettingsNewOutlinedIcon style={{ color: "#BB86FC" }} />
              }
              onClick={logoutUser}
            >
              Logout
            </MenuItem> */}
          </Box>
        </Menu>
      </Sidebar>
    </>
  );
};

export default SidebarComponent;
