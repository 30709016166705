import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";

const cardStyles = {
  // Card container
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  justifyContent: "flex-end",
  overflow: "hidden",
  width: "100%",
  textAlign: "center",
  color: "white",
  boxShadow:
    "0 1px 1px rgba(0,0,0,0.1), 0 2px 2px rgba(0,0,0,0.1), 0 4px 4px rgba(0,0,0,0.1), 0 8px 8px rgba(0,0,0,0.1), 0 16px 16px rgba(0,0,0,0.1)",
  height: "300px",
  "&:before": {
    // Overlay
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage:
      "linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.55) 100%)",
  },
  "&:hover": {
    "&:before": {
      backgroundImage:
        "linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%)",
    },
  },
  "@media (max-width:899px)": {
    width: "80%",
  },
};

const ImageCard = ({ title, description, imageUrl }) => {
  return (
    <Box width="100%" display="flex" justifyContent="center">
      <Card
        sx={{
          ...cardStyles,
          background: `url(${imageUrl}) center / cover no-repeat`,
        }}
      >
        <CardContent sx={{ position: "relative", zIndex: 2, p: 2 }}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ color: "white", fontWeight: "bold" }}
          >
            {title}
          </Typography>
          <Typography variant="body2" color="white" sx={{ mb: 1.5 }}>
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ImageCard;
