import React, { useContext, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { IoPeopleSharp } from "react-icons/io5";
import AuthContext from "../../context/AuthContext";
import DashboardCard from "./DashboardCard";
import "./Dashboard.css";
import ChartOfNewPatientVisits from "./ChartOfNewPatientVisits";
import { darkThemeStyles } from "../../styledComponents/CustomStyleComponents";
import { Helmet } from "react-helmet";

const Dashboard = () => {
  const { fetchUserInfo, getPatientCountAPI, patientCount, userInfo } =
    useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchUserInfo(), getPatientCountAPI()]);
    };

    fetchData();
  }, []);

  const cardData = [
    {
      style: {
        background: darkThemeStyles.surface, // Changed according to the provided color scheme
        color: darkThemeStyles.onSurface, // Title color changed to Bright Gray
      },
      title: "Number Of Patients",
      icon: <IoPeopleSharp />,
      quantity: patientCount,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Denama | Dashboard</title>
      </Helmet>
      <Box width="100%" padding="1rem" bgcolor="#121212">
        <Box fontSize="26px" color={darkThemeStyles.onSurface}>
          Hello {userInfo ? `Dr. ${userInfo.first_name}` : ""}, Welcome Back 👋
        </Box>
        <Box py={3}>
          <Grid container spacing={5}>
            {cardData.map((card, index) => (
              <Grid item xs={12} md={12} lg={6} key={index}>
                <DashboardCard
                  styleOfDashboard={card.style}
                  title={card.title}
                  bgIcon={card.icon}
                  quantity={card.quantity}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        <ChartOfNewPatientVisits />
      </Box>
    </>
  );
};

export default Dashboard;
