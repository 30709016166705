export const isObjectContainsAnyBlankValue = (data) => {
  let containsBlank = false;
  let keyValue = null;
  for (let key in data) {
    if (data[key] === "") {
      containsBlank = true;
      keyValue = key;
      return { containsBlank, keyValue };
    }
  }
  containsBlank = false;
  return { containsBlank, keyValue };
};
