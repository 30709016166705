import { Navigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const PrivateRoute = ({ children }) => {
  const { username } = useContext(AuthContext);

  return username ? children : <Navigate to="/" replace />;
};

export default PrivateRoute;
