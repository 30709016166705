import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
export const sidebarElement = [
  {
    elementPath: "/dashboard",
    icon: <HomeOutlinedIcon />,
    name: "Dashboard",
  },
  {
    elementPath: "my-profile",
    icon: <ContactsOutlinedIcon />,
    name: "Profile",
  },
  {
    elementPath: "support",
    icon: <ContactSupportOutlinedIcon />,
    name: "Support",
  },
  {
    elementPath: "change-password",
    icon: <VpnKeyOutlinedIcon />,
    name: "Change Password",
  },
];
