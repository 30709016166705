import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo_without_bg.png"; // Import your logo here
import { Helmet } from "react-helmet";

const ErrorPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Helmet>
        <title>Denama | 404 - Page Not Found</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "#C6DCEA",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: "#1E3A56",
          minHeight: "100vh",
          padding: isMobile ? "1rem" : "2rem",
        }}
      >
        <Box sx={{ position: "absolute", top: "1rem", left: "1rem" }}>
          <Link to="/">
            <Box component="span">
              <img
                src={Logo}
                alt=""
                style={{ width: isMobile ? "50px" : "90px" }}
                height="auto"
              />
            </Box>
            <Box
              component="span"
              sx={{
                fontSize: { xs: "25px", sm: "28px", md: "30px" },
                fontWeight: 600,
                color: "#0C356A",
              }}
            >
              Denama
            </Box>
          </Link>
        </Box>
        <Box>
          <Box borderBottom="1px solid #1E3A56" padding="0 0 20px 0">
            <Box
              fontSize={isMobile ? "20px" : "25px"}
              fontWeight="600"
              py="10px"
              letterSpacing="2px"
            >
              404 - Oops :(
            </Box>
            <Box color="#2B4865">
              Looks like the page you are trying to visit doesn't exist.
            </Box>
          </Box>
          <Box pt="20px">
            Maybe the page you are looking for was renamed, deleted, or might
            have never existed. Please check the URL and try again.
          </Box>
          <Box paddingTop="2rem">
            <Button
              variant="contained"
              startIcon={<ArrowBackOutlinedIcon />}
              onClick={() => navigate("/")}
            >
              Back to Home
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ErrorPage;
