import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import { createTheme } from "@mui/material";

export const darkThemeStyles = {
  background: "#1A1A1A",
  surface: "#1e1e1e",
  onSurface: "#E0E0E0",
  primary: "#BB86FC",
  secondary: "#03DAC6",
  error: "#CF6679",
  onPrimary: "#000000",
};

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: darkThemeStyles.background,
      paper: darkThemeStyles.surface,
    },
    text: {
      primary: darkThemeStyles.onSurface,
      secondary: darkThemeStyles.secondary,
    },
    primary: {
      main: darkThemeStyles.primary,
    },
    secondary: {
      main: darkThemeStyles.secondary,
    },
    error: {
      main: darkThemeStyles.error,
    },
  },
});

export const textFieldOnlyStyles = {
  "& label.Mui-focused": { color: darkThemeStyles.onSurface },
  "& .MuiInput-underline:after": {
    borderBottomColor: darkThemeStyles.secondary,
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: "rgba(255, 255, 255, 0.3)" },
    "&:hover fieldset": { borderColor: darkThemeStyles.onSurface },
    "&.Mui-focused fieldset": {
      borderColor: darkThemeStyles.secondary, // Subtler color
    },
  },
  "& .MuiInputBase-input": { color: darkThemeStyles.onSurface },
  "& .MuiInputLabel-root": { color: "rgba(255, 255, 255, 0.5)" },
  // Style for the adornment
  "& .MuiInputAdornment-root": {
    "& .MuiTypography-root": {
      color: "rgb(229, 228, 226)", // Platinum color
    },
  },
  transition: "border-color 300ms", // Smooth transition for focus
};

export const saveButtonStyle = {
  backgroundColor: darkThemeStyles.secondary, // A color from your dark theme palette
  color: darkThemeStyles.onPrimary,
  "&:hover": {
    backgroundColor: "rgb(3 218 198 / 90%)", // Lighten the background color a bit
    boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.2)", // Soft shadow for depth
  },
  "&:disabled": {
    backgroundColor: "#2a2a2a", // Darker or muted color for disabled state
    color: "#5e5e5e", // Dim the text color
    cursor: "not-allowed", // Change cursor to indicate non-interactivity
    boxShadow: "none", // Remove shadow to flatten the appearance
    pointerEvents: "auto",
  },
  transition: "all 300ms",
};

export const stylesForSelectComponent = {
  ...textFieldOnlyStyles,
  "& .MuiSelect-select": {
    backgroundColor: darkThemeStyles.surface,
    color: darkThemeStyles.onSurface,
  },
  "& .MuiSvgIcon-root": {
    color: darkThemeStyles.onSurface,
  },
};

export const stylesForSelectProps = {
  MenuProps: {
    sx: {
      "& .MuiPaper-root": {
        bgcolor: darkThemeStyles.surface, // Dark background for the dropdown
        color: darkThemeStyles.onSurface, // Text color for the dropdown items
      },
      "& .MuiMenuItem-root": {
        "&:hover": {
          bgcolor: darkThemeStyles.primary, // Dark hover for the dropdown items
        },
        "&.Mui-selected": {
          backgroundColor: "rgba(255, 255, 255, 0.2)", // Selected item background
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.25)", // Hover on selected item
          },
        },
      },
    },
  },
};
export const CustomTextField = styled(TextField)(() => ({
  // Override styles for enable fields
  ...textFieldOnlyStyles,
  "& .MuiInputBase-input": {
    color: "orange",
    padding: "3.5px 5px",
    "&::placeholder": {
      color: "#e0e0e0b8",
      opacity: 0.8,
    },
  },
  // Override styles for disabled state
  "&& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "orange", // Override for webkit browsers
    color: "orange", // Override text color
    cursor: "not-allowed",
  },
  "&& .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255, 255, 255, 0.7)", // Your preferred disabled border color
  },
}));
