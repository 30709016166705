import React from "react";
import "./Loading.css";
const Loading = () => {
  return (
    <section className="loading-section">
      <div className="loading-content">
        <h2>Denama</h2>
        <h2>Denama</h2>
      </div>
    </section>
  );
};

export default Loading;