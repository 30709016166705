import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActions, CardHeader, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AuthContext from "../../context/AuthContext";
import Grid from "@mui/material/Grid";

const CardForNote = ({ data }) => {
  const { notesDeleteAPI } = useContext(AuthContext);

  // Add this array of colors
  const colors = [
    "#ffc8dd",
    "#c8b6ff",
    "#fbf8cc",
    "#e5e5e5",
    "#ade8f4",
    "#b8b8ff",
  ];
  const deleteHandler = (id) => {
    notesDeleteAPI(id);
  };

  return (
    <Grid container spacing={2} className="cards-for-note-section">
      {data?.map(({ title, content, id }, index) => (
        <Grid item key={index}>
          <Box sx={{ padding: "10px" }} key={index}>
            <Card
              variant="outlined"
              sx={{
                width: 250,
                border: "0.2px solid black",
                borderRadius: "8px",
                backgroundColor: colors[index % colors.length], // use the color
                transition: "transform 0.15s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <CardHeader title={title} sx={{ padding: "10px 16px" }} />
              <CardContent
                sx={{
                  height: 150,
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none", // changed to camelCase
                  scrollbarWidth: "none",
                  padding: "0px 15px",
                }}
              >
                <Typography sx={{ color: "black" }}>{content}</Typography>
              </CardContent>
              <CardActions
                sx={{ justifyContent: "flex-end", padding: "0 8px" }}
              >
                <IconButton
                  aria-label="delete"
                  onClick={deleteHandler.bind(null, id)}
                >
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
export default CardForNote;
