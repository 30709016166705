import React from "react";
import { Grid, TextField, MenuItem } from "@mui/material";

const PersonalDetailsPanel = ({ getFieldProps }) => (
  <>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          {...getFieldProps("email")}
          label="Email Address"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="outlined-select-currency"
          {...getFieldProps("gender")}
          select
          label="Gender"
          fullWidth
        >
          <MenuItem value="M">Male</MenuItem>
          <MenuItem value="F">Female</MenuItem>
          <MenuItem value="O">Other</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...getFieldProps("date_of_birth")}
          type="date"
          label="Date Of Birth"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...getFieldProps("contact_timings")}
          label="Contact Timings"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...getFieldProps("official_contact_number")}
          label="Official Phone Number"
          type="number"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...getFieldProps("personal_contact_number")}
          label="Personal Phone Number"
          type="number"
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          {...getFieldProps("qualification")}
          label="Qualification"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...getFieldProps("additional_qualification")}
          label="Additional Qualification"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...getFieldProps("residence_address")}
          label="Residence Address"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...getFieldProps("clinic_address")}
          label="Clinic Address"
          variant="outlined"
          fullWidth
        />
      </Grid>
    </Grid>
  </>
);

export default PersonalDetailsPanel;
