import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import logo from "../../assets/images/logo_without_bg.png";
import headerBg from "../../assets/images/pdfHeaderBg.jpg";

const styles = StyleSheet.create({
  header: {
    position: "relative",
    height: "100px",
    backgroundColor: "#F2F2F2", // This color should match the dominant color of the image background
  },
  background: {
    position: "absolute",
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  content: {
    flexDirection: "row",
    justifyContent: "space-between", // Spread out the logo and details on both ends
    alignItems: "center",
    position: "relative",
    padding: "10px 20px",
  },
  logo: {
    width: "auto",
    height: 70,
  },
  doctorDetailsContainer: {
    flexDirection: "column",
    alignItems: "flex-end", // Align to the end to match image 2 style
  },
  doctorName: {
    fontSize: 16,
    fontFamily: "Lato Bold",
    color: "#000000",
    marginBottom: 4, // Spacing after the name
  },
  doctorInfo: {
    fontSize: 10,
    fontFamily: "Lato",
    color: "#000000",
    marginBottom: 1,
  },
});

const PrescriptionHeader = ({
  name,
  qualification,
  contactNumber,
  contactTimings,
  clinicAddress,
  registrationNumber,
}) => {
  return (
    <View style={styles.header} fixed>
      <Image src={headerBg} style={styles.background} />
      <View style={styles.content}>
        <Image src={logo} style={styles.logo} />
        <View style={styles.doctorDetailsContainer}>
          <Text style={styles.doctorName}>{`Dr. ${name || ""}`}</Text>
          <Text style={styles.doctorInfo}>{qualification || ""}</Text>
          <Text style={styles.doctorInfo}>{`Registration No: ${
            registrationNumber || ""
          }`}</Text>
          <Text style={styles.doctorInfo}>{`Address: ${
            clinicAddress || ""
          }`}</Text>
          <Text style={styles.doctorInfo}>{`Contact No: ${
            contactNumber || ""
          }`}</Text>
          <Text style={styles.doctorInfo}>{`Timings: ${
            contactTimings || ""
          }`}</Text>
        </View>
      </View>
    </View>
  );
};

export default PrescriptionHeader;
