import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Stack } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AuthContext from "../../context/AuthContext";
import {
  INITIAL_PATIENT_ANALYSIS_OBJ,
  INITIAL_PATIENT_INFO_OBJ,
} from "../../context/contextUtils";
import { useNavigate } from "react-router";
import SaveIcon from "@mui/icons-material/Save";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import PatientDeleteAlertDialog from "./PatientDeleteAlertDialog";
import { debounce } from "lodash";

const StyledButton = styled(Button)(() => ({
  background: "#333333",
  color: "#FFFFFF",
  width: "100%", // make the button full width in column layout
  textTransform: "none",
  "&.Mui-disabled": {
    background: "#373737",
    color: "#757575",
    "& .MuiSvgIcon-root": {
      color: "#757575",
    },
  },
  "&:hover": {
    background: "#505050",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    color: "#FFFFFF",
  },
}));

const isSaveBtnDisable = (
  selectedPatientRowId,
  isrevisitBtnClicked,
  selectedVisitAnalysisAndMedicineData
) => {
  return !!selectedPatientRowId
    ? isrevisitBtnClicked
      ? selectedVisitAnalysisAndMedicineData === null
        ? false
        : true
      : true
    : false;
};

const PatientActions = () => {
  const {
    setPatientInfo,
    setPatientAnalysis,
    patientInfoPostAPI,
    patientInfoRevisitPostAPI,
    setSelectedPatientRowId,
    setSelectedPatientAndAllVisitsData,
    setSelectedVisitAnalysisAndMedicineData,
    setMediList,
    selectedPatientRowId,
    isrevisitBtnClicked,
    setIsrevisitBtnClicked,
    selectedVisitAnalysisAndMedicineData,
    setPatientSearchResults,
    setSearchInputValue,
    patientInfo,
    patientAnalysis,
    mediList,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);

  const newBtnHandler = () => {
    setPatientAnalysis(INITIAL_PATIENT_ANALYSIS_OBJ);
    setPatientInfo(INITIAL_PATIENT_INFO_OBJ);
    setSelectedPatientRowId("");
    setMediList([
      {
        med_name: "",
        med_schedule: "",
        med_instructions: "",
        med_route: "",
        number_of_days: 0,
      },
    ]);
    setSelectedPatientAndAllVisitsData(null);
    setSelectedVisitAnalysisAndMedicineData(null);
    setIsrevisitBtnClicked(false);
    setPatientSearchResults(null);
    setSearchInputValue("");
  };

  const revisitBtnHandler = () => {
    setIsrevisitBtnClicked(true);
    setPatientAnalysis(INITIAL_PATIENT_ANALYSIS_OBJ);
    setMediList([
      {
        med_name: "",
        med_schedule: "",
        med_instructions: "",
        med_route: "",
        number_of_days: 0,
      },
    ]);
    setSelectedVisitAnalysisAndMedicineData(null);
  };

  const prescriptionDownloadHandler = () => {
    navigate(
      `/prescription-download/${selectedPatientRowId}/${selectedVisitAnalysisAndMedicineData?.visit_details.visit_id}`
    );
  };

  const deleteBtnHandler = async () => {
    setOpenDeleteAlert(true);
  };

  // patient save action
  const saveBtnHandler = () => {
    let isDisabled = isSaveBtnDisable(
      selectedPatientRowId,
      isrevisitBtnClicked,
      selectedVisitAnalysisAndMedicineData
    );

    if (!isDisabled) {
      if (isrevisitBtnClicked) {
        patientInfoRevisitPostAPI();
      } else {
        patientInfoPostAPI();
      }
    }
  };

  const debouncedSaveBtnHandler = debounce(saveBtnHandler, 500); // 500 ms debounce time while saving data

  // Function to handle keyboard shortcuts
  const handleKeyboardShortcut = (event) => {
    // event.metaKey is used for macOS
    // event.ctrlKey is used for windows
    if (
      (event.ctrlKey || event.metaKey) &&
      (event.key === "s" || event.key === "S")
    ) {
      event.preventDefault(); // Prevents the default browser action
      debouncedSaveBtnHandler();
    }
  };

  // Set up the event listener
  useEffect(() => {
    window.addEventListener("keydown", handleKeyboardShortcut);

    // Clean up the event listener
    return () => {
      window.removeEventListener("keydown", handleKeyboardShortcut);
    };
  }, [patientInfo, patientAnalysis, mediList]);

  return (
    <>
      <section style={{ borderBottom: "1px solid #e1e1e1", flex: 1 }}>
        <div className="p-heading">Actions</div>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignItems="center"
          padding={1}
        >
          <StyledButton
            startIcon={<AddCircleRoundedIcon />}
            onClick={newBtnHandler}
            aria-label="new-btn"
          >
            New
          </StyledButton>
          <StyledButton
            aria-label="save-btn"
            startIcon={<SaveIcon />}
            onClick={debouncedSaveBtnHandler}
            disabled={isSaveBtnDisable(
              selectedPatientRowId,
              isrevisitBtnClicked,
              selectedVisitAnalysisAndMedicineData
            )}
          >
            Save
          </StyledButton>
          <StyledButton
            aria-label="delete-btn"
            onClick={deleteBtnHandler}
            startIcon={<DeleteOutlineRoundedIcon />}
            disabled={!Boolean(selectedPatientRowId)}
          >
            Delete
          </StyledButton>
          <StyledButton
            aria-label="revisit-btn"
            startIcon={<EditNoteIcon />}
            onClick={revisitBtnHandler}
            disabled={!selectedPatientRowId}
          >
            Re-Visit
          </StyledButton>
          <StyledButton
            startIcon={<PrintIcon />}
            aria-label="print-btn"
            disabled={
              selectedPatientRowId ? (isrevisitBtnClicked ? true : false) : true
            }
            onClick={prescriptionDownloadHandler}
          >
            Printout
          </StyledButton>
        </Stack>
      </section>
      <PatientDeleteAlertDialog
        open={openDeleteAlert}
        setOpen={setOpenDeleteAlert}
      />
    </>
  );
};

export default PatientActions;
