export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

export const INITIAL_PATIENT_ANALYSIS_OBJ = {
  chiefComplaints: "",
  physicalExamination: "",
  historyOfIllness: "",
  allergyDetails: "",
  investigationAdvised: "",
  provisionalDiagnosis: "",
  previousMedicine: "",
};

export const INITIAL_PATIENT_INFO_OBJ = {
  name: "",
  ageInYears: "",
  ageInMonths: "",
  ageInDays: "",
  gender: "",
  phoneNumber: "",
  reference: "",
  quickNote: "",
  address: "",
  email: "",
};

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const convertToTitleCase = (str) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const convertToTitleCaseWithSpace = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const OPTIONAL_FIELDS = [
  // from patient info
  "quick_notes",
  "email",
  "reference",
  // from patient analysis
  "history_of_illness",
  "allergy_details",
  "physical_examination",
  "previous_medicines",
  "investigations_advised",
  "provisional_diagnosis",
  // for treatment and recommendation
  "med_schedule",
  "med_instructions",
  "med_route",
  "number_of_days",
];

export const validatePatientData = (data) => {
  for (const key in data) {
    const keyTitleCase = convertToTitleCaseMapper(key);

    // Skip optional fields if they are empty
    if (
      OPTIONAL_FIELDS.includes(key) &&
      (data[key] === "" || data[key] === undefined)
    ) {
      continue;
    }

    // Handle nested objects
    if (typeof data[key] === "object" && !Array.isArray(data[key])) {
      const result = validatePatientData(data[key]);
      if (!result.isSuccess) {
        return result;
      }
    }
    // Handle arrays, specifically the 'medicines' array
    else if (Array.isArray(data[key]) && key === "medicines") {
      if (data[key].length === 0) {
        return {
          isSuccess: false,
          failureMessage: `You must enter at least one medicine to proceed.`,
        };
      }
      for (const medicine of data[key]) {
        for (const medKey in medicine) {
          if (
            OPTIONAL_FIELDS.includes(medKey) &&
            (medicine[medKey] === "" || medicine[medKey] === undefined)
          ) {
            continue;
          }
          if (medicine[medKey] === undefined || medicine[medKey] === "") {
            return {
              isSuccess: false,
              failureMessage: `The field '${convertToTitleCaseMapper(
                medKey
              )}' under Medicines is required and cannot be left blank.`,
            };
          }
        }
      }
    }
    // Validate gender
    else if (
      key === "gender" &&
      !["male", "female", "other", "m", "f", "o"].includes(
        data[key].toLowerCase()
      )
    ) {
      return {
        isSuccess: false,
        failureMessage:
          "Please select a gender from the options: Male (M), Female (F), or Other (O).",
      };
    }
    // Validate email
    else if (key === "email" && !validateEmail(data[key])) {
      return {
        isSuccess: false,
        failureMessage:
          "The email address entered appears to be invalid. Please check it and try again.",
      };
    }

    // Validate age
    else if (
      key === "age_in_years" ||
      key === "age_in_months" ||
      key === "age_in_days"
    ) {
      if (
        !(data["age_in_years"] || data["age_in_months"] || data["age_in_days"])
      ) {
        return {
          isSuccess: false,
          failureMessage: "Please enter age and try again.",
        };
      }
    }

    // Validate mobile
    else if (key === "mobile" && !/^\d{10}$/.test(data[key])) {
      return {
        isSuccess: false,
        failureMessage:
          "The mobile number entered is not valid. It must be exactly 10 digits without any other characters.",
      };
    }
    // Check for empty fields
    else if (data[key] === undefined || data[key] === "") {
      return {
        isSuccess: false,
        failureMessage: `The field '${keyTitleCase}' is required and cannot be left blank.`,
      };
    }
  }

  // If no errors were found
  return { isSuccess: true, failureMessage: "" };
};

// Helper function to convert string to Title Case
const convertToTitleCaseMapper = (str) => {
  return str
    .replace(/_/g, " ") // replaces underscores with spaces
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");
};

export const dataMapperAccordingToFrontendForNewPatientVisit = (
  patientInfo,
  patientAnalysis,
  mediList
) => {
  return {
    patient_details: {
      name: patientInfo.name,
      age_in_years: patientInfo.ageInYears,
      age_in_months: patientInfo.ageInMonths,
      age_in_days: patientInfo.ageInDays,
      gender: patientInfo.gender,
      mobile: patientInfo.phoneNumber,
      address: patientInfo.address,
      reference: patientInfo.reference,
      email: patientInfo.email,
      quick_notes: patientInfo.quickNote,
    },
    analysis_details: {
      chief_complaints: patientAnalysis.chiefComplaints,
      history_of_illness: patientAnalysis.historyOfIllness,
      allergy_details: patientAnalysis.allergyDetails,
      physical_examination: patientAnalysis.physicalExamination,
      provisional_diagnosis: patientAnalysis.provisionalDiagnosis,
      investigations_advised: patientAnalysis.investigationAdvised,
      previous_medicines: patientAnalysis.previousMedicine,
    },
    medicines: removeEmptyObjectsFromMedicineArray(mediList),
  };
};

export const dataMapperAccordingToFrontendForPatientReVisit = (
  patientId,
  patientAnalysis,
  mediList
) => {
  return {
    patient_id: patientId,
    analysis_details: {
      chief_complaints: patientAnalysis.chiefComplaints,
      history_of_illness: patientAnalysis.historyOfIllness,
      allergy_details: patientAnalysis.allergyDetails,
      physical_examination: patientAnalysis.physicalExamination,
      provisional_diagnosis: patientAnalysis.provisionalDiagnosis,
      investigations_advised: patientAnalysis.investigationAdvised,
      previous_medicines: patientAnalysis.previousMedicine,
    },
    medicines: removeEmptyObjectsFromMedicineArray(mediList),
  };
};

const removeEmptyObjectsFromMedicineArray = (array) => {
  return array.filter((obj) => {
    return !Object.values(obj).every((value) => value === "" || value === 0);
  });
};

export const stringToColorForAvatar = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const ageCalculator = (dataOfPatient) => {
  // NOTE: make sure the visit dates should be in descending order

  // Extract the first visit date
  let firstVisitDate =
    dataOfPatient.visits[dataOfPatient.visits.length - 1].visit_details
      .visit_date;
  // Convert first visit date to a proper format (YYYY-MM-DD)
  let [day, month, year] = firstVisitDate.split(" ")[0].split("/");
  let formattedVisitDate = `${year}-${month}-${day}`;

  // Create date objects
  let visitDate = new Date(formattedVisitDate);
  let currentDate = new Date();

  // Calculate the difference in years, months, and days
  let ageYears = currentDate.getFullYear() - visitDate.getFullYear();
  let ageMonths = currentDate.getMonth() - visitDate.getMonth();
  let ageDays = currentDate.getDate() - visitDate.getDate();

  // Adjust for leap year
  if (ageDays < 0) {
    let previousMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      0
    );
    ageDays += previousMonth.getDate();
    ageMonths--;
  }

  if (ageMonths < 0) {
    ageMonths += 12;
    ageYears--;
  }

  // console.log(`Data = ${ageYears} years, ${ageMonths} months, ${ageDays} days`);

  // Add the calculated difference to the existing age
  let totalYears = dataOfPatient.patient_details.age_in_years
    ? ageYears + parseFloat(dataOfPatient.patient_details.age_in_years)
    : 0;
  let totalMonths = dataOfPatient.patient_details.age_in_months
    ? ageMonths + parseFloat(dataOfPatient.patient_details.age_in_months)
    : 0;
  let totalDays = dataOfPatient.patient_details.age_in_days
    ? ageDays + parseFloat(dataOfPatient.patient_details.age_in_days)
    : 0;

  // Adjust for overflow in months and days
  if (totalDays >= 30) {
    totalMonths += Math.floor(totalDays / 30);
    totalDays %= 30;
  }

  if (totalMonths >= 12) {
    totalYears += Math.floor(totalMonths / 12);
    totalMonths %= 12;
  }

  // Update the patient details
  dataOfPatient.patient_details.age_in_years = totalYears.toFixed(2);
  dataOfPatient.patient_details.age_in_months = totalMonths.toFixed(2);
  dataOfPatient.patient_details.age_in_days = totalDays.toFixed(2);
};
