import React, { useContext, useEffect } from "react";
import AuthContext from "../../context/AuthContext";
import { INITIAL_PATIENT_INFO_OBJ } from "../../context/contextUtils";
import { Grid, Box, Tooltip, InputAdornment } from "@mui/material";
import { CustomTextField } from "../../styledComponents/CustomStyleComponents";

const inputFields = [
  {
    label: "Name",
    name: "name",
    type: "text",
    placeholder: "Enter Patient's Full Name",
    required: true,
  },
  // Grouped age fields
  {
    label: "Age",
    name: "age",
    required: true,
    subFields: [
      {
        name: "ageInYears",
        placeholder: "",
        type: "number",
        adornment: "Years",
      },
      {
        name: "ageInMonths",
        placeholder: "",
        type: "number",
        adornment: "Months",
      },
      {
        name: "ageInDays",
        placeholder: "",
        type: "number",
        adornment: "Days",
      },
    ],
  },
  {
    label: "Gender",
    name: "gender",
    type: "text",
    placeholder: "Enter Gender: M, F, O",
    required: true,
  },
  {
    label: "Mobile",
    name: "phoneNumber",
    type: "number",
    placeholder: "Enter mobile number",
    required: true,
  },
  {
    label: "Address",
    name: "address",
    type: "text",
    placeholder: "Enter address",
    required: true,
  },
  {
    label: "Reference",
    name: "reference",
    type: "text",
    placeholder: "How did you hear about us?",
    required: false,
  },
  {
    label: "Email",
    name: "email",
    type: "email",
    placeholder: "Enter email address",
    required: false,
  },
  // {
  //   label: "Quick Note",
  //   name: "quickNote",
  //   type: "text",
  //   placeholder: "Any important notes?",
  //   required: false,
  // },
];

const PatientInfo = () => {
  const { patientInfo, setPatientInfo, selectedPatientAndAllVisitsData } =
    useContext(AuthContext);
  const isDisabled = !!selectedPatientAndAllVisitsData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!selectedPatientAndAllVisitsData) {
      setPatientInfo((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (selectedPatientAndAllVisitsData?.patient_details) {
      const patientDetails = selectedPatientAndAllVisitsData.patient_details;
      const newData = {
        name: patientDetails.name || "",
        ageInYears: +patientDetails.age_in_years || "",
        ageInMonths: +patientDetails.age_in_months || "",
        ageInDays: +patientDetails.age_in_days || "",
        gender: patientDetails.gender || "",
        phoneNumber: patientDetails.mobile || "",
        reference: patientDetails.reference || "",
        email: patientDetails.email || "",
        quickNote: patientDetails.quick_notes || "",
        address: patientDetails.address || "",
      };
      setPatientInfo(newData);
    } else {
      setPatientInfo(INITIAL_PATIENT_INFO_OBJ);
    }
  }, [selectedPatientAndAllVisitsData, setPatientInfo]);

  return (
    <section>
      <div className="p-heading">Patient Profile</div>
      <Box py={1} px={2}>
        <Grid container spacing={1}>
          {inputFields.map((field) => (
            <React.Fragment key={field.name}>
              {!field.subFields ? (
                <>
                  <Grid item xs={12} md={2.5}>
                    {field.required ? (
                      <label htmlFor={field.name} style={{ color: "#E0E0E0" }}>
                        {field.label}
                        <Tooltip title="Required" placement="top" arrow>
                          <span style={{ color: "red" }}>&nbsp;*</span>
                        </Tooltip>
                      </label>
                    ) : (
                      <label htmlFor={field.name} style={{ color: "#E0E0E0" }}>
                        {field.label}
                      </label>
                    )}
                  </Grid>
                  <Grid item xs={12} md={9.5}>
                    <CustomTextField
                      fullWidth
                      type={field.type}
                      name={field.name}
                      value={patientInfo[field.name] || ""}
                      onChange={handleChange}
                      disabled={isDisabled}
                      placeholder={isDisabled ? "" : field.placeholder}
                      variant="outlined"
                      inputProps={{
                        "aria-label": field.label,
                      }}
                      autoComplete="off"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 0,
                          backgroundColor: "#292929",
                        },
                      }}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} md={2.5}>
                    <label htmlFor={field.name} style={{ color: "#E0E0E0" }}>
                      {field.label}
                      {field.required && (
                        <Tooltip title="Required" placement="top" arrow>
                          <span style={{ color: "red" }}>&nbsp;*</span>
                        </Tooltip>
                      )}
                    </label>
                  </Grid>
                  <Grid item xs={12} md={9.5}>
                    <Box display="flex" flexDirection="row">
                      {field.subFields.map((subField) => (
                        <CustomTextField
                          key={subField.name}
                          fullWidth
                          type={subField.type}
                          name={subField.name}
                          value={patientInfo[subField.name]}
                          onChange={handleChange}
                          disabled={isDisabled}
                          placeholder={isDisabled ? "" : subField.placeholder}
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                {subField.adornment}
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: 0,
                              backgroundColor: "#292929",
                            },
                          }}
                        />
                      ))}
                    </Box>
                  </Grid>
                </>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </section>
  );
};

export default PatientInfo;
