import { Box, Typography } from "@mui/material";

export const FILTER_DAILY = "daily"; // last 15 days data
export const FILTER_MONTHLY = "monthly"; // current month monthly data
export const FILTER_YEARLY = "yearly"; // all years data which have atleast 1 patient visit

export const mergeArraysBySameKey = (array1, array2, filterValue) => {
  let key =
    filterValue === FILTER_MONTHLY
      ? "month"
      : filterValue === FILTER_YEARLY
      ? "year"
      : "date";
  let map = new Map();

  array1.forEach((item) =>
    map.set(item[key], { ...item, count: item.count || 0 })
  );

  array2.forEach((item) => {
    if (map.has(item[key])) {
      let mergedItem = map.get(item[key]);
      mergedItem.revisit_count = item.revisit_count || 0;
      map.set(item[key], mergedItem);
    } else {
      map.set(item[key], { ...item, count: 0 });
    }
  });

  return Array.from(map.values());
};

// Custom dot component
export const CustomSquareDot = (props) => {
  const { cx, cy, stroke } = props;

  return (
    <rect
      x={cx - 5}
      y={cy - 5}
      width={10}
      height={10}
      fill={stroke}
      stroke={stroke}
    />
  );
};

export const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: "white",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <Typography>Date: {payload[0].payload.date}</Typography>
        <Typography>New Patients: {payload[0].value}</Typography>
        <Typography>Revisits: {payload[1].value}</Typography>
      </Box>
    );
  }

  return null;
};
