import React, { createRef, useContext, useRef, useState } from "react";
import { Button, Card, Box, CircularProgress } from "@mui/material";
import { INITIAL_FIELDS_OBJ_OF_REGISTER_PAGE } from "../AccountsHelper";
import UsernamePasswordPanel from "./UsernamePasswordPanel";
import PersonalDetailsPanel from "./PersonalDetailsPanel";
import { grey } from "@mui/material/colors";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AuthContext from "../../../context/AuthContext";

const steps = ["Basic Details", "Personal Details"];

const RegisterStepsCard = () => {
  const { registerUserAPI } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState(INITIAL_FIELDS_OBJ_OF_REGISTER_PAGE);

  const [errors, setErrors] = useState({});

  const inputRefs = useRef(
    Object.keys(formData).reduce((acc, value) => {
      acc[value.name] = createRef();
      return acc;
    }, {})
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validate = () => {
    const tempErrors = {};
    let isValid = true;

    // Define mandatory fields
    const mandatoryFields = [
      "first_name",
      "email",
      "username",
      "password",
      "confirmPassword",
      "qualification",
      "official_contact_number",
      "gender",
      "date_of_birth",
    ];

    let start_index = activeStep === 0 ? 0 : 5;
    let end_index = activeStep === 0 ? 5 : Object.keys(formData).length;
    Object.keys(formData)
      .slice(start_index, end_index)
      .forEach((key) => {
        // Check for mandatory fields
        if (
          mandatoryFields.includes(key) &&
          (!formData[key] || !formData[key].trim())
        ) {
          tempErrors[key] = "Required and cannot contain only spaces";
          if (isValid) {
            inputRefs[key]?.current?.focus();
            isValid = false;
          }
        }
      });

    // Email validation
    if (formData.email) {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!emailRegex.test(formData.email)) {
        tempErrors.email = "Please enter a valid email address";
        if (isValid) {
          inputRefs.email?.current?.focus();
          isValid = false;
        }
      }
    }

    // Personal Phone number validation (assuming personal_contact_number is the phone number field)
    if (
      formData.personal_contact_number &&
      !/^\d{10}$/.test(formData.personal_contact_number)
    ) {
      tempErrors.personal_contact_number =
        "The mobile number must be exactly 10 digits without any other characters";
      if (isValid) {
        inputRefs.personal_contact_number?.current?.focus();
        isValid = false;
      }
    }

    // Official Phone number validation (assuming personal_contact_number is the phone number field)
    if (
      formData.official_contact_number &&
      !/^\d{10}$/.test(formData.official_contact_number)
    ) {
      tempErrors.official_contact_number =
        "The mobile number must be exactly 10 digits without any other characters";
      if (isValid) {
        inputRefs.official_contact_number?.current?.focus();
        isValid = false;
      }
    }

    // Password validation
    if (formData.password) {
      // Define password criteria
      const minLength = 8;
      const hasUpperCase = /[A-Z]/.test(formData.password);
      const hasLowerCase = /[a-z]/.test(formData.password);
      const hasNumbers = /\d/.test(formData.password);
      const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(formData.password);

      // Check if password meets criteria
      if (
        formData.password.length < minLength ||
        !hasUpperCase ||
        !hasLowerCase ||
        !hasNumbers ||
        !hasSpecialChars
      ) {
        tempErrors.password =
          "Password must be at least 8 characters long, including an uppercase letter, a lowercase letter, a number, and a special character.";
        if (isValid) {
          inputRefs.password?.current?.focus();
          isValid = false;
        }
      }
    }

    // Password and Confirm Password Validation
    if (
      formData.password &&
      formData.confirmPassword &&
      formData.password !== formData.confirmPassword
    ) {
      tempErrors.confirmPassword =
        "The confirmation password does not match the original. Please double-check.";
      if (isValid) {
        inputRefs.confirmPassword?.current?.focus();
        isValid = false;
      }
    }

    setErrors(tempErrors);
    return isValid;
  };

  const getFieldProps = (name) => ({
    ref: inputRefs[name],
    name: name,
    value: formData[name],
    error: !!errors[name],
    helperText: errors[name],
    onChange: handleInputChange,
  });

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      if (activeStep === steps.length - 1) {
        setLoading(true);
        // Ensure a minimum of 1 seconds loading
        registerUserAPI(formData).finally(() => {
          setLoading(false);
          // setFormData(INITIAL_FIELDS_OBJ_OF_REGISTER_PAGE);
        });
      } else {
        handleNext();
      }
    }
  };

  return (
    <>
      <Card
        sx={{
          padding: "40px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          width: { xs: "80%", sm: "80%", lg: "40%" },
          borderRadius: "8px",
          "@media (max-width:700px)": {
            padding: "15px",
          },
        }}
      >
        <Box width="100%">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingBottom="10px"
          >
            <Box
              width="40px"
              height="40px"
              borderRadius="50%"
              sx={{ bgcolor: "rgb(246, 248, 251)" }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <PersonOutlineOutlinedIcon
                sx={{
                  color: "rgb(120, 139, 165)",
                }}
              />
            </Box>
          </Box>

          <Box
            component="h1"
            sx={{
              fontSize: "24px",
              lineHeight: 1.5,
              textAlign: "center",
              fontWeight: 600,
              "@media (max-width:700px)": {
                fontSize: "20px",
              },
              "@media (max-width:360px)": {
                fontSize: "19px",
              },
            }}
          >
            Sign up
          </Box>
          <Box
            sx={{
              color: grey[600],
              textAlign: "center",
              mb: 2,
              "@media (max-width:700px)": {
                fontSize: "15px",
              },
            }}
          >
            Create your Denama account with your work email
          </Box>
          {/* <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              return (
                <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper> */}
          <Box component="form" onSubmit={submitHandler}>
            <Box sx={{ mt: 2, mb: 1 }}>
              {activeStep === 0 && (
                <UsernamePasswordPanel getFieldProps={getFieldProps} />
              )}
              {activeStep === 1 && (
                <PersonalDetailsPanel getFieldProps={getFieldProps} />
              )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              {activeStep > 0 && (
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1, borderRadius: "50px" }}
                  variant="contained"
                >
                  Back
                </Button>
              )}

              <Box sx={{ flex: "1 1 auto" }} />

              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                sx={{
                  backgroundColor: "#0066cc",
                  "&:hover": {
                    backgroundColor: "#004d99",
                  },
                  borderRadius: "50px",
                }}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : activeStep === steps.length - 1 ? (
                  "Create Account"
                ) : (
                  "Next"
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default RegisterStepsCard;
