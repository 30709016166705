import { createContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../api/api";
import {
  INITIAL_PATIENT_ANALYSIS_OBJ,
  INITIAL_PATIENT_INFO_OBJ,
  ageCalculator,
  dataMapperAccordingToFrontendForNewPatientVisit,
  dataMapperAccordingToFrontendForPatientReVisit,
  validatePatientData,
} from "./contextUtils";
import Loading from "../Layout/Loading";
const AuthContext = createContext({});

export default AuthContext;

// TODO: custom hook of the API
// TODO: Constant Magic Numbers: The interval you have to refresh the tokens (let fourMinutes = 1000 * 60 * 4;) is currently a magic number. This should be stored as a constant at the top of your file.
// TODO: Token Storage: Tokens stored in local storage can be vulnerable to XSS attacks. Look into storing your tokens in a more secure way, such as in an httpOnly cookie.

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  let tokens = localStorage.getItem("authTokens"); // getting authTokens (which is in string form) from local storage
  // *NOTE: using callback function here, so that the value should set only one time when the page loads
  const [username, setUsername] = useState(() =>
    tokens ? jwt_decode(JSON.parse(tokens).access).username : null
  );
  const [authTokens, setAuthTokens] = useState(() =>
    tokens ? JSON.parse(tokens) : null
  );
  let [loading, setLoading] = useState(authTokens ? true : false);

  let [initialLoading, setInitialLoading] = useState(true); // state for initial loading

  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
    severity: "warning",
  });

  /**
   *
   * User Info GET API
   */

  const [userInfo, setUserInfo] = useState();

  const fetchUserInfo = async () => {
    try {
      const response = await fetch(`${BASE_URL}/user-info/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access}`,
        },
      });
      if (response.status !== 200) {
        // setNotification({
        //   isOpen: true,
        //   message: "Sorry, your user information not found. Logging out!",
        //   severity: "error",
        // });
        throw new Error(
          "Sorry, your user information not found. Logging out.."
        );
      }
      const data = await response.json();
      const userData = data?.response;
      if (userData) {
        setUserInfo(userData);
      }
    } catch (error) {
      logoutUser();
    }
  };

  /**
   *
   * User Info Update API
   */
  const updateUserInfo = async (userInfoData) => {
    try {
      let form_data = new FormData();
      // Append the user info fields to the FormData object
      for (const key in userInfoData) {
        if (userInfoData[key]) {
          form_data.append(key, userInfoData[key]);
        }
      }

      // Append the profile_pic as a File
      if (userInfoData.profile_pic instanceof File) {
        form_data.append("profile_pic", userInfoData.profile_pic);
      }

      const response = await fetch(`${BASE_URL}/user-info/`, {
        method: "PATCH",
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authTokens?.access}`,
        },
        body: form_data,
      });
      if (response.status === 200) {
        await fetchUserInfo();
        setNotification({
          isOpen: true,
          message: "User Information Updated Successfully.",
          severity: "success",
        });
      } else {
        setNotification({
          isOpen: true,
          message:
            "Something went wrong while updating profile, Please try again later.",
          severity: "error",
        });
      }
    } catch (error) {}
  };

  /*
    LOGIN API
   */
  let loginUserHandler = async (username, password) => {
    try {
      let response = await fetch(`${BASE_URL}/token/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });
      let data = await response.json();
      if (response.ok) {
        setAuthTokens(data);
        let decoded = jwt_decode(data.access);
        setUsername(decoded.username);
        localStorage.setItem("authTokens", JSON.stringify(data));
        navigate("/dashboard");
      } else {
        throw new Error("Unable to log in.");
      }
    } catch (error) {
      // handling edge cases
      if (!navigator.onLine) {
        setNotification({
          isOpen: true,
          message: "No internet connection.",
          severity: "error",
        });
      } else if (error.message.includes("500")) {
        setNotification({
          isOpen: true,
          message: "No internet connection.",
          severity: "error",
        });
      } else if (error.message.includes("Failed")) {
        setNotification({
          isOpen: true,
          message: "Sorry, It's not you it's us. Please try after sometime.",
          severity: "error",
        });
      } else {
        setNotification({
          isOpen: true,
          message: "No active account found with the given credentials.",
          severity: "error",
        });
      }
    }
  };

  /*
    Register API
   */
  let registerUserAPI = async (data) => {
    let response = await fetch(`${BASE_URL}/register/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let resData = await response.json();
    if (response.status === 201) {
      setNotification({
        isOpen: true,
        message: "Registered Successfully",
        severity: "success",
      });
      navigate("/login");
    } else if (response.status === 400) {
      let errorMessage = "";
      if ("username" in resData.message) {
        errorMessage = `Username: ${resData.message.username[0]}`;
      } else if ("password" in resData.message) {
        errorMessage = `Password: ${resData.message.password[0]}`;
      } else if ("email" in resData.message) {
        errorMessage = `Email: ${resData.message.email[0]}`;
      } else {
        errorMessage = "Something went wrong with the form!";
      }
      setNotification({
        isOpen: true,
        message: errorMessage,
        severity: "error",
      });
    } else {
      setNotification({
        isOpen: true,
        message: "Something went wrong!",
        severity: "error",
      });
    }
  };

  /*
    LOGOUT
   */
  let logoutUser = () => {
    setAuthTokens(null);
    setUsername(null);
    localStorage.removeItem("authTokens");
    navigate("/login");
  };

  /*
    Update Access Token Using Refresh Token
   */
  let updateToken = async () => {
    try {
      let date = new Date();
      // console.log("Token Refresh at ", date.toLocaleTimeString());
      let response = await fetch(`${BASE_URL}/token/refresh/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh: authTokens?.refresh,
        }),
      });
      let data = await response.json();
      if (response.ok) {
        localStorage.setItem("authTokens", JSON.stringify(data));
        setAuthTokens(data);
        let decoded = jwt_decode(data.access);
        setUsername(decoded.username);
      } else {
        // if something fails then we logout the user
        logoutUser();
      }
      if (loading) {
        setLoading(false);
      }
    } catch (error) {
      // handling edge cases
      if (!navigator.onLine) {
        setNotification({
          isOpen: true,
          message: "No internet connection.",
          severity: "error",
        });
      } else if (error.message.includes("500")) {
        setNotification({
          isOpen: true,
          message: "Server is down.",
          severity: "error",
        });
      } else {
        setNotification({
          isOpen: true,
          message: "Something went wrong.",
          severity: "error",
        });
      }
    }
  };

  // in this we are updating accesstokens using updateTokens function
  useEffect(() => {
    if (loading) {
      updateToken();
    }
    // here we are using 4 mins because the lifespan of access token is 5 mins
    // so for efficiency we are updating it after every 4 mins
    let fourMinutes = 1000 * 60 * 4;

    let interval = setInterval(() => {
      if (authTokens) {
        updateToken();
      }
    }, fourMinutes);

    return () => clearInterval(interval);
  }, [authTokens, loading]);

  // useEffect for initial loading
  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialLoading(false);
    }, 2000); // Set initial loading to false after 3 seconds
    return () => clearTimeout(timer);
  }, []);

  /**
   * Medicine Fetch API
   */
  const [allMedicineData, setAllMedicineData] = useState([]);
  const [isMedicineDataUpdated, setIsMedicineDataUpdated] = useState(false);

  const fetchMedicineData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/medicine-info/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access}`,
        },
      });
      const data = await response.json();
      const medicineData = data?.response;
      if (medicineData) {
        setAllMedicineData(medicineData);
      }
    } catch (error) {}
  };

  // useEffect(() => {
  //   if (authTokens) {
  //     fetchMedicineData();
  //   }
  // }, [isMedicineDataUpdated]);

  /**
   * Medicine Data POST API
   */

  const [openNewMedicineAddForm, setOpenNewMedicineAddForm] = useState(false);

  const newMedInfoAddHandler = async (formData) => {
    try {
      const response = await fetch(`${BASE_URL}/medicine-info/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(formData),
      });
      if (response.status === 201) {
        setIsMedicineDataUpdated(true);
        setOpenNewMedicineAddForm(false);
        return true;
      } else {
        // notification
        setNotification({
          isOpen: true,
          message: "Something went wrong.",
          severity: "error",
        });
        return false;
      }
    } catch (error) {
      setNotification({
        isOpen: true,
        message: "Internal server error.",
        severity: "error",
      });
      return false;
    }
  };

  /**
   * Medicine Delete API
   */
  const medicineDeleteAPI = async (id) => {
    let response = await fetch(`${BASE_URL}/medicine-info/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    if (response.status === 200) {
      setMedicineSelected(null);
      setIsMedicineDataUpdated(true);
      // notification
      setNotification({
        isOpen: true,
        message: "Medicine Deleted Successfully.",
        severity: "success",
      });
    }
  };

  /**
   * Medicine Update API
   */
  const [openEditMedicineForm, setOpenEditMedicineForm] = useState(false);
  const [isMedicineEditedSuccessfully, setIsMedicineEditedSuccessfully] =
    useState(false);
  const updateMedInfoAddHandler = async (formData) => {
    setIsMedicineEditedSuccessfully(false);
    setIsMedicineDataUpdated(false);
    try {
      const response = await fetch(
        `${BASE_URL}/medicine-info/${formData?.id}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens.access}`,
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.status === 200) {
        setIsMedicineDataUpdated(true);
        setOpenEditMedicineForm(false);
        setIsMedicineEditedSuccessfully(true);
        setMedicineSelected(formData);
        // notification
        setNotification({
          isOpen: true,
          message: "Medicine Data Updated Successfully.",
          severity: "success",
        });
      } else {
        // notification
        setNotification({
          isOpen: true,
          message: "Something went wrong in medicine update.",
          severity: "error",
        });
      }
    } catch (error) {
      // notification
      setNotification({
        isOpen: true,
        message: "Internal server error while performing medicine update.",
        severity: "error",
      });
    }
  };

  useEffect(() => {}, [isMedicineEditedSuccessfully]);

  /**
   * Medicine Selection State
   */
  const [medicineSelected, setMedicineSelected] = useState(null);

  /**
   * Medicine Copy and Add
   */
  const [isMedCopyAndAddBtnOpen, setIsMedCopyAndAddBtnOpen] = useState(false);

  /**
   * Search MedicineId
   */
  const [medicineSelectedAfterSearch, setMedicineSelectedAfterSearch] =
    useState(null);

  /**
   * Patient Information POST API
   */
  const [patientInfo, setPatientInfo] = useState(INITIAL_PATIENT_INFO_OBJ);

  const [patientAnalysis, setPatientAnalysis] = useState(
    INITIAL_PATIENT_ANALYSIS_OBJ
  );

  const [patientMedicineList, setPatientMedicineList] = useState([]);
  // const [isPatientInfoUpdated, setIsPatientInfoUpdated] = useState(false);
  const [mediList, setMediList] = useState([
    {
      med_name: "",
      med_schedule: "",
      med_instructions: "",
      med_route: "",
      number_of_days: 0,
    },
  ]);

  /**
   *  Patient Data selected or not
   */
  const [selectedPatientRowId, setSelectedPatientRowId] = useState(null);

  const [selectedPatientAndAllVisitsData, setSelectedPatientAndAllVisitsData] =
    useState(null);

  const [
    selectedVisitAnalysisAndMedicineData,
    setSelectedVisitAnalysisAndMedicineData,
  ] = useState(null);

  /**
   * Patient Create API
   */
  const patientInfoPostAPI = async () => {
    const apiBodyData = dataMapperAccordingToFrontendForNewPatientVisit(
      patientInfo,
      patientAnalysis,
      mediList
    );
    let { isSuccess, failureMessage } = validatePatientData(apiBodyData);
    if (isSuccess) {
      let response = await fetch(`${BASE_URL}/visit-prescription/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(apiBodyData),
      });
      const data = await response.json();

      if (response.status === 201) {
        // notification
        setNotification({
          isOpen: true,
          message: "Patient Info Added Successfully.",
          severity: "success",
        });

        if (data?.response) {
          let patientId = data?.response.patient_id;
          setSelectedPatientRowId(patientId);
          fetchPatientDataUsingPatientId(patientId);
        }
      } else {
        // notification
        setNotification({
          isOpen: true,
          message: "Something went wrong or Improperly input data!",
          severity: "error",
        });
      }
    } else {
      // notification
      setNotification({
        isOpen: true,
        message: failureMessage,
        severity: "error",
      });
    }
  };

  /**
   * All Patients GET API
   */
  const [patientData, setPatientData] = useState([]);
  const [isPatientDataFetch, setIsPatientDataFetch] = useState(false);
  const fetchPatientData = async () => {
    try {
      setIsPatientDataFetch(false);
      const response = await fetch(`${BASE_URL}/patient-info/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access}`,
        },
      });
      const data = await response.json();
      const patientData = data?.response;
      if (patientData) {
        setPatientData(patientData);
      } else {
        setPatientData([]);
      }
    } catch (error) {
    } finally {
      setIsPatientDataFetch(true);
    }
  };

  /**
   * Patient search API
   */
  const [patientSearchResults, setPatientSearchResults] = useState(null);
  const [searchInputValue, setSearchInputValue] = useState("");
  const fetchPatientDataAfterSearch = async (field_name, field_value) => {
    try {
      const response = await fetch(
        `${BASE_URL}/patient-search/?field_name=${field_name}&field_value=${field_value}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access}`,
          },
        }
      );
      const data = await response.json();
      setPatientSearchResults(data.results || []);
    } catch (error) {}
  };

  /**
   * GET Patient Info Using Id
   */
  const fetchPatientDataUsingPatientId = async (patientId) => {
    try {
      const response = await fetch(`${BASE_URL}/patient-info/${patientId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access}`,
        },
      });
      const data = await response.json();
      const patientData = data?.response;
      if (patientData) {
        ageCalculator(patientData);
        setSelectedPatientAndAllVisitsData(patientData);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  /**
   * GET Patient Info Using Id
   */
  const [pdfDataUsingPatientAndVisitData, setPdfDataUsingPatientAndVisitData] =
    useState(null);

  const fetchPatientDataUsingPatientIdAndVisitId = async (
    patientId,
    visitId
  ) => {
    try {
      const response = await fetch(`${BASE_URL}/patient-info/${patientId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access}`,
        },
      });
      const data = await response.json();
      const patientData = data?.response;
      if (patientData) {
        ageCalculator(patientData);
        let visitData = patientData.visits.filter(
          (data) => data.visit_details.visit_id === visitId
        )[0];
        let responseData = {
          personalData: patientData.patient_details,
          analysisData: visitData.analysis_details || "",
          medicineData: visitData.medicine || "",
          visitDate: visitData.visit_details.visit_date || "",
        };
        setPdfDataUsingPatientAndVisitData(responseData);
      }
    } catch (error) {}
  };

  /**
   * Patient Revisit API
   */
  const [isrevisitBtnClicked, setIsrevisitBtnClicked] = useState(false);

  const patientInfoRevisitPostAPI = async () => {
    let patientId = selectedPatientAndAllVisitsData.patient_details.patient_id;
    const apiBodyData = dataMapperAccordingToFrontendForPatientReVisit(
      patientId,
      patientAnalysis,
      mediList
    );
    let { isSuccess, failureMessage } = validatePatientData(apiBodyData);
    if (isSuccess) {
      let response = await fetch(`${BASE_URL}/revisit-prescription/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(apiBodyData),
      });
      const data = await response.json();
      if (response.status === 201) {
        // notification
        setNotification({
          isOpen: true,
          message: "Patient Visit Information Added Successfully.",
          severity: "success",
        });
        if (data?.response) {
          let patientId = data?.response.patient_id;
          setSelectedPatientRowId(patientId);
          fetchPatientDataUsingPatientId(patientId);
          setIsrevisitBtnClicked(false);
        }
      } else {
        // notification
        setNotification({
          isOpen: true,
          message: "Something went wrong or Improperly input data!",
          severity: "error",
        });
      }
    } else {
      // notification
      setNotification({
        isOpen: true,
        message: failureMessage,
        severity: "error",
      });
    }
  };

  /**
   * patient save btn
   */
  const [showSaveBtnPatient, setShowSaveBtnPatient] = useState(true);

  /**
   * Get patient count on the basis of active user
   */
  const [patientCount, setPatientCount] = useState(0);
  const getPatientCountAPI = async () => {
    try {
      const response = await fetch(`${BASE_URL}/get-patient-count/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access}`,
        },
      });
      const data = await response.json();
      const patientCountData = data?.response;
      if (patientCountData) {
        setPatientCount(patientCountData.count);
      }
    } catch (error) {}
  };

  /**
   * Get medicine suggestions
   */
  const getMedicineSuggestionsForPatients = async (value) => {
    try {
      const response = await fetch(
        `${BASE_URL}/get-medicine-suggestions?field_value=${value}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access}`,
          },
        }
      );

      if (!response.ok) {
        // Handle HTTP errors
        throw new Error(`Isssue in getting medicine suggestions!`);
      }

      const data = await response.json();
      const reqData = data.results.map((val) => val.med_name);
      return reqData;
    } catch (error) {
      console.error("Error fetching medicine suggestions:", error);
      return [];
    }
  };

  /**
   * Get medicine count on the basis of active user
   */
  const [medicineCount, setMedicineCount] = useState(0);
  const getMedicineCountAPI = async () => {
    try {
      const response = await fetch(`${BASE_URL}/get-medicine-count/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access}`,
        },
      });
      const data = await response.json();
      const medicineCountData = data?.response;
      if (medicineCountData) {
        setMedicineCount(medicineCountData.count);
      }
    } catch (error) {}
  };

  /**
   * Delete patient API
   */
  const patientDeleteAPI = async (patientId) => {
    let response = await fetch(`${BASE_URL}/patient-info/${patientId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    if (response.status === 200) {
      // notification
      setNotification({
        isOpen: true,
        message: "Patient Information Deleted Successfully.",
        severity: "success",
      });
    } else {
      setNotification({
        isOpen: true,
        message: "Something went wrong while Deletion of Patient.",
        severity: "error",
      });
    }
  };

  /**
   * Notes GET API
   */
  const [allNotesData, setAllNotesData] = useState([]);
  const [isNoteUpdate, setIsNoteUpdate] = useState(false);

  const fetchNotesData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/notes/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access}`,
        },
      });
      const data = await response.json();
      const notesData = data?.response;
      if (notesData) {
        setAllNotesData(notesData);
      } else {
        setAllNotesData([]);
      }
    } catch (error) {}
  };

  /**
   * Notes POST API
   */
  const notePostData = async (data) => {
    setIsNoteUpdate(false);
    const response = await fetch(`${BASE_URL}/notes/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens?.access}`,
      },
      body: JSON.stringify(data),
    });
    if (response.status === 201) {
      setIsNoteUpdate(true);
      // notification
      setNotification({
        isOpen: true,
        message: "Note Added Successfully.",
        severity: "success",
      });
    } else {
      setNotification({
        isOpen: true,
        message: "Something went wrong in adding Note.",
        severity: "error",
      });
    }
  };

  /**
   * Notes DELETE API
   */
  const notesDeleteAPI = async (id) => {
    setIsNoteUpdate(false);
    let response = await fetch(`${BASE_URL}/notes/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    if (response.status === 200) {
      setIsNoteUpdate(true);
      // notification
      setNotification({
        isOpen: true,
        message: "Note Deleted Successfully.",
        severity: "success",
      });
    } else {
      setNotification({
        isOpen: true,
        message: "Something went wrong while Deletion of Note.",
        severity: "error",
      });
    }
  };

  /**
   * Reset Password API
   */
  const resetPasswordAPI = async (oldPassword, newPassword) => {
    let response = await fetch(`${BASE_URL}/change-password/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({
        old_password: oldPassword,
        new_password: newPassword,
      }),
    });
    if (response.status === 200) {
      setNotification({
        isOpen: true,
        message: "Password reset successfully.",
        severity: "success",
      });
      return true;
    } else if (response.status === 400) {
      setNotification({
        isOpen: true,
        message: "Incorrect old password, Please try again later.",
        severity: "error",
      });
    } else {
      setNotification({
        isOpen: true,
        message: "Something went wrong, Please try again later.",
        severity: "error",
      });
    }
    return false;
  };

  /**
   * Get Support Data
   */
  const [supportTicketsData, setSupportTicketsData] = useState(null);
  const fetchSupportTicketsData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/support/${userInfo?.user}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access}`,
        },
      });

      if (!response.ok) {
        // Check if response status is not OK
        setSupportTicketsData(null);
      }

      const data = await response.json();
      const supportData = data?.response;
      if (supportData) {
        setSupportTicketsData(supportData);
      } else {
        setSupportTicketsData(null);
      }
    } catch (error) {
      // console.log("Failed to fetch support ticket data:", error.message);
    }
  };

  /**
   * POST Support Data
   */
  const supportTicketPostAPI = async (data) => {
    let formData = new FormData();
    // Append the user info fields to the FormData object
    for (const key in data) {
      if (data[key]) {
        if (key === "file_uploads" && Array.isArray(data[key])) {
          data[key].forEach((file) => {
            formData.append("file_uploads", file);
          });
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    const response = await fetch(`${BASE_URL}/support/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authTokens?.access}`,
      },
      body: formData,
    });
    if (response.status === 201) {
      setNotification({
        isOpen: true,
        message: "Issue Created successfully.",
        severity: "success",
      });
      return true;
    } else {
      setNotification({
        isOpen: true,
        message: "Something went wrong in creating issue.",
        severity: "error",
      });
      return false;
    }
  };

  /**
   * Support Delete API
   */
  const supportTicketDeleteAPI = async (id) => {
    let response = await fetch(`${BASE_URL}/support/${id}/`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    if (response.status === 200) {
      // notification
      setNotification({
        isOpen: true,
        message: "Ticket Deleted Successfully.",
        severity: "success",
      });
    } else {
      setNotification({
        isOpen: true,
        message: "Something went wrong while Deletion of Ticket.",
        severity: "error",
      });
    }
  };

  /**
   * patientVisitStats API
   */
  const [graphStatsData, setGraphStatsData] = useState();

  const getPatientVisitStats = async (filterValue) => {
    try {
      const response = await fetch(
        `${BASE_URL}/patient-visit-stats?filter=${filterValue}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access}`,
          },
        }
      );

      if (!response.ok) {
        return { data: [], message: "Internal Server Error" };
      }

      const data = await response.json();
      const responseData = data?.response;
      if (responseData.length !== 0) {
        setGraphStatsData(responseData);
      } else {
        setGraphStatsData(null);
      }
    } catch (error) {
      setGraphStatsData(null);
    }
  };

  /**
   * patient revisit stats (new patients are not included i.e., with single visits)
   */
  const [revisitGraphStatsData, setRevisitGraphStatsData] = useState();

  const getPatientReVisitStats = async (filterValue) => {
    try {
      const response = await fetch(
        `${BASE_URL}/patient-revisit-stats?filter=${filterValue}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authTokens?.access}`,
          },
        }
      );

      if (!response.ok) {
        return { data: [], message: "Internal Server Error" };
      }

      const data = await response.json();
      const responseData = data?.response;
      if (responseData.length !== 0) {
        setRevisitGraphStatsData(responseData);
      } else {
        setRevisitGraphStatsData(null);
      }
    } catch (error) {
      setRevisitGraphStatsData(null);
    }
  };

  /**
   * User Forgot Password API (Sending Reset Password Link)
   */
  const [resetPasswordLinkMessage, setResetPasswordLinkMessage] =
    useState(null);

  const resetPasswordLinkSendingAPI = async (value) => {
    try {
      let response = await fetch(`${BASE_URL}/password-reset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          value: value,
        }),
      });
      const data = await response.json();

      if (response.status === 200) {
        return setResetPasswordLinkMessage(data);
      } else {
        return setResetPasswordLinkMessage(data); // in case of invalid value
      }
    } catch (error) {}
  };

  /**
   * Medicine suggestions management (GET Request)
   */
  const getMedicineSuggestionsWithActiveAndDisableField = async (
    query = "",
    isActiveFilter
  ) => {
    const params = new URLSearchParams();
    if (query) {
      params.append("query", query);
    }
    if (isActiveFilter === true || isActiveFilter === false) {
      params.append("is_active", isActiveFilter);
    }

    const fetchUrl = `${BASE_URL}/medicine-suggestions-management?${params.toString()}`;

    try {
      const response = await fetch(fetchUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access}`,
        },
      });

      if (!response.ok) {
        // Handle HTTP errors
        throw new Error(`Isssue in getting medicine suggestions!`);
      }

      const data = await response.json();
      return { results: data.results, count: data.count };
    } catch (error) {
      console.error("Error fetching medicine suggestions:", error);
      return { results: [], count: 0 };
    }
  };

  const medicineSuggestionsChangeActiveAndInactive = async (data) => {
    const response = await fetch(
      `${BASE_URL}/medicine-suggestions-management/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens?.access}`,
        },
        body: JSON.stringify(data),
      }
    );
    if (response.status === 200) {
      setNotification({
        isOpen: true,
        message: "Medicine Suggestions Updated Successfully.",
        severity: "success",
      });
    } else {
      setNotification({
        isOpen: true,
        message: "Something went wrong in updating medicine suggestions.",
        severity: "error",
      });
    }
  };

  /*********************************************** */

  let contextData = {
    loginUserHandler: loginUserHandler,
    authTokens: authTokens,
    username: username,
    logoutUser: logoutUser,
    registerUserAPI: registerUserAPI,

    // user
    fetchUserInfo: fetchUserInfo,
    userInfo: userInfo,
    updateUserInfo: updateUserInfo,
    resetPasswordAPI: resetPasswordAPI,

    // medicine
    allMedicineData: allMedicineData,
    setIsMedicineDataUpdated: setIsMedicineDataUpdated,
    isMedicineDataUpdated: isMedicineDataUpdated,
    newMedInfoAddHandler: newMedInfoAddHandler,
    openNewMedicineAddForm: openNewMedicineAddForm,
    setOpenNewMedicineAddForm: setOpenNewMedicineAddForm,
    medicineDeleteAPI: medicineDeleteAPI,
    medicineSelected: medicineSelected,
    setMedicineSelected: setMedicineSelected,
    medicineSelectedAfterSearch: medicineSelectedAfterSearch,
    setMedicineSelectedAfterSearch: setMedicineSelectedAfterSearch,
    setOpenEditMedicineForm: setOpenEditMedicineForm,
    openEditMedicineForm: openEditMedicineForm,
    updateMedInfoAddHandler: updateMedInfoAddHandler,
    isMedCopyAndAddBtnOpen: isMedCopyAndAddBtnOpen,
    setIsMedCopyAndAddBtnOpen: setIsMedCopyAndAddBtnOpen,
    fetchMedicineData: fetchMedicineData,

    getMedicineCountAPI: getMedicineCountAPI,
    medicineCount: medicineCount,

    // patient
    patientInfo: patientInfo,
    setPatientInfo: setPatientInfo,
    patientAnalysis: patientAnalysis,
    setPatientAnalysis: setPatientAnalysis,
    patientInfoPostAPI: patientInfoPostAPI,
    patientData: patientData,
    setSelectedPatientRowId: setSelectedPatientRowId,
    selectedPatientRowId: selectedPatientRowId,
    fetchPatientData: fetchPatientData,
    isPatientDataFetch: isPatientDataFetch,
    fetchPatientDataUsingPatientId: fetchPatientDataUsingPatientId,
    patientMedicineList: patientMedicineList,
    setPatientMedicineList: setPatientMedicineList,
    mediList: mediList,
    setMediList: setMediList,

    selectedPatientAndAllVisitsData: selectedPatientAndAllVisitsData,
    setSelectedPatientAndAllVisitsData: setSelectedPatientAndAllVisitsData,
    selectedVisitAnalysisAndMedicineData: selectedVisitAnalysisAndMedicineData,
    setSelectedVisitAnalysisAndMedicineData:
      setSelectedVisitAnalysisAndMedicineData,
    isrevisitBtnClicked: isrevisitBtnClicked,
    setIsrevisitBtnClicked: setIsrevisitBtnClicked,
    patientInfoRevisitPostAPI: patientInfoRevisitPostAPI,
    showSaveBtnPatient: showSaveBtnPatient,
    setShowSaveBtnPatient: setShowSaveBtnPatient,

    patientCount: patientCount,
    getPatientCountAPI: getPatientCountAPI,
    patientDeleteAPI: patientDeleteAPI,

    // patient search
    fetchPatientDataAfterSearch: fetchPatientDataAfterSearch,
    patientSearchResults: patientSearchResults,
    setPatientSearchResults: setPatientSearchResults,
    searchInputValue: searchInputValue,
    setSearchInputValue: setSearchInputValue,

    // medicine suggestions
    getMedicineSuggestionsForPatients: getMedicineSuggestionsForPatients,

    // prescription pdf
    fetchPatientDataUsingPatientIdAndVisitId:
      fetchPatientDataUsingPatientIdAndVisitId,
    pdfDataUsingPatientAndVisitData: pdfDataUsingPatientAndVisitData,

    // notes
    allNotesData: allNotesData,
    isNoteUpdate: isNoteUpdate,
    setIsNoteUpdate: setIsNoteUpdate,
    notePostData: notePostData,
    notesDeleteAPI: notesDeleteAPI,
    fetchNotesData: fetchNotesData,

    // notification
    notification: notification,
    setNotification: setNotification,

    // support panel
    fetchSupportTicketsData: fetchSupportTicketsData,
    supportTicketsData: supportTicketsData,
    supportTicketPostAPI: supportTicketPostAPI,
    supportTicketDeleteAPI: supportTicketDeleteAPI,

    // patientVisitStats Data
    getPatientVisitStats: getPatientVisitStats,
    graphStatsData: graphStatsData,
    setGraphStatsData: setGraphStatsData,

    // revisitGraphStats Data
    getPatientReVisitStats: getPatientReVisitStats,
    revisitGraphStatsData: revisitGraphStatsData,
    setRevisitGraphStatsData: setRevisitGraphStatsData,

    // resetPasswordLinkSendingAPI
    resetPasswordLinkSendingAPI: resetPasswordLinkSendingAPI,
    resetPasswordLinkMessage: resetPasswordLinkMessage,
    setResetPasswordLinkMessage: setResetPasswordLinkMessage,

    // medicine suggestions management
    getMedicineSuggestionsWithActiveAndDisableField:
      getMedicineSuggestionsWithActiveAndDisableField,

    medicineSuggestionsChangeActiveAndInactive:
      medicineSuggestionsChangeActiveAndInactive,
  };
  return (
    <AuthContext.Provider value={contextData}>
      {initialLoading ? <Loading /> : loading ? <Loading /> : children}
    </AuthContext.Provider>
  );
};
