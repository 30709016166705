import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Card,
  Grid,
  TextField,
  Button,
  Avatar,
  Typography,
  MenuItem,
  CardHeader,
  Divider,
  CardContent,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import AuthContext from "../../context/AuthContext";
import {
  darkThemeStyles,
  saveButtonStyle,
  stylesForSelectComponent,
  stylesForSelectProps,
  textFieldOnlyStyles,
} from "../../styledComponents/CustomStyleComponents";
import {
  convertToTitleCase,
  stringToColorForAvatar,
} from "../../context/contextUtils";
import { BACKEND_BASE_URL } from "../../api/api";
import { isObjectContainsAnyBlankValue } from "./UserHelper";
import { Helmet } from "react-helmet";

const cardTitleStyle = {
  color: darkThemeStyles.onSurface,
  fontSize: "1.2rem",
  fontWeight: 500,
};

const UserProfile = () => {
  const { userInfo, fetchUserInfo, updateUserInfo, setNotification } =
    useContext(AuthContext);

  const [userInfoData, setUserInfoData] = useState(userInfo);

  const [userInfoDataForUpdate, setUserInfoDataForUpdate] = useState(null);
  const [image, setImage] = useState(
    userInfoData?.profile_pic
      ? `${BACKEND_BASE_URL}${userInfoData.profile_pic}`
      : null
  );

  const username = `${userInfo?.first_name || ""} ${userInfo?.last_name || ""}`;

  const firstLastCharSplit = `${
    username.split(" ")[0] ? username.split(" ")[0][0] : username.split(" ")[0]
  }${
    username.split(" ")[1] ? username.split(" ")[1][0] : username.split(" ")[1]
  }`;

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      // Handle case where no valid files were accepted (e.g., show an error message)
      return;
    }

    const file = acceptedFiles[0];
    if (!file.type.startsWith("image/")) {
      // Handle case for non-image files
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
    handleChange("profile_pic", file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
    maxSize: 10485760, // Example: 10MB limit
  });

  const handleChange = (fieldName, newValue) => {
    setUserInfoData((prevData) => ({
      ...prevData,
      [fieldName]: newValue,
    }));
    setUserInfoDataForUpdate((prevData) => ({
      ...prevData,
      [fieldName]: newValue,
    }));
  };

  const handleSubmit = () => {
    let { containsBlank, keyValue } = isObjectContainsAnyBlankValue(
      userInfoDataForUpdate
    );
    if (containsBlank) {
      setNotification({
        isOpen: true,
        message: `${convertToTitleCase(keyValue)} can not be Blank.`,
        severity: "error",
      });
    } else {
      updateUserInfo(userInfoDataForUpdate).finally(() =>
        setUserInfoDataForUpdate(null)
      );
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  useEffect(() => {
    setUserInfoData(userInfo);
  }, [userInfo]);

  useEffect(() => {
    setImage(
      userInfoData?.profile_pic
        ? `${BACKEND_BASE_URL}${userInfoData.profile_pic}`
        : null
    );
  }, [userInfoData?.profile_pic]);

  // Compute the avatar color only when rendering
  const computedAvatarColor = stringToColorForAvatar(username);

  return (
    <>
      <Helmet>
        <title>Denama | Profile</title>
      </Helmet>
      <Box
        display={"flex"}
        justifyContent={"center"}
        width="100%"
        alignItems={"center"}
        bgcolor="#121212"
        color="#e1e1e1"
      >
        <Box
          sx={{
            backgroundColor: darkThemeStyles.background,
            color: darkThemeStyles.onSurface,
            margin: "1.5rem",
          }}
        >
          <Card
            sx={{
              backgroundColor: darkThemeStyles.surface,
              padding: 2,
              borderRadius: 2,
            }}
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4} lg={3} sx={{ textAlign: "center" }}>
                  <Box
                    my={5}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box {...getRootProps()} sx={{ cursor: "pointer" }}>
                      <input {...getInputProps()} />
                      {image ? (
                        <>
                          <Avatar
                            src={image}
                            sx={{
                              width: 150,
                              height: 150,
                              bgcolor: "#E0E0E0",
                              border: "3px solid white",
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Avatar
                            sx={{
                              bgcolor: computedAvatarColor,
                              width: 150,
                              height: 150,
                              border: "3px solid white",
                            }}
                          >
                            {firstLastCharSplit}
                          </Avatar>
                        </>
                      )}
                    </Box>
                    <Typography
                      variant="body2"
                      color="white"
                      pt={3}
                      width="70%"
                    >
                      Click on the image above to upload a new profile picture.{" "}
                      <br />
                      (Allowed *.jpeg, *.jpg, *.png)
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                  {/* Personal Information Section */}
                  <CardHeader
                    title={
                      <Typography style={cardTitleStyle}>
                        Personal Information
                      </Typography>
                    }
                    sx={{ paddingBottom: 0 }}
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="first_name"
                          autoComplete="given-name"
                          label="First Name"
                          name="first_name"
                          value={userInfoData?.first_name || ""}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          sx={textFieldOnlyStyles}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="last_name"
                          autoComplete="family-name"
                          label="Last Name"
                          name="last_name"
                          value={userInfoData?.last_name || ""}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          sx={textFieldOnlyStyles}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          type="date"
                          name="date_of_birth"
                          label="Date Of Birth"
                          value={userInfoData?.date_of_birth || ""}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          sx={{
                            ...textFieldOnlyStyles,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            inputProps: {
                              max: "9999-12-31", // to avoid invalid date format error in Chrome for years > 9999
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          select
                          id="gender"
                          label="Gender"
                          name="gender"
                          value={userInfoData?.gender || ""}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          sx={stylesForSelectComponent}
                          SelectProps={stylesForSelectProps}
                        >
                          <MenuItem value="M">Male</MenuItem>
                          <MenuItem value="F">Female</MenuItem>
                          <MenuItem value="O">Others</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  </CardContent>

                  {/* Additional Information Section */}
                  <CardHeader
                    title={
                      <Typography style={cardTitleStyle}>
                        Professional Information
                      </Typography>
                    }
                    sx={{ paddingTop: 2, paddingBottom: 0 }}
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="Registration Number"
                          name="registration_number"
                          value={userInfoData?.registration_number || ""}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          sx={textFieldOnlyStyles}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Qualification"
                          name="qualification"
                          value={userInfoData?.qualification || ""}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          sx={textFieldOnlyStyles}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Additional Qualification"
                          name="additional_qualification"
                          value={userInfoData?.additional_qualification || ""}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          sx={textFieldOnlyStyles}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>

                  {/* Contact Information Section */}
                  <CardHeader
                    title={
                      <Typography style={cardTitleStyle}>
                        Contact Information
                      </Typography>
                    }
                    sx={{ paddingTop: 2, paddingBottom: 0 }}
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="email"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                          value={userInfoData?.email || ""}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          sx={textFieldOnlyStyles}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Contact Timings"
                          name="contact_timings"
                          value={userInfoData?.contact_timings || ""}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          sx={textFieldOnlyStyles}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Personal Phone Number"
                          name="personal_contact_number"
                          type="number"
                          value={userInfoData?.personal_contact_number || ""}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          sx={textFieldOnlyStyles}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Official Phone Number"
                          name="official_contact_number"
                          type="number"
                          value={userInfoData?.official_contact_number || ""}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          sx={textFieldOnlyStyles}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label="Clinic Address"
                          name="clinic_address"
                          value={userInfoData?.clinic_address || ""}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          sx={textFieldOnlyStyles}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Residence Address"
                          name="residence_address"
                          value={userInfoData?.residence_address || ""}
                          variant="outlined"
                          fullWidth
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                          sx={textFieldOnlyStyles}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <Box sx={{ textAlign: "right", marginTop: 2 }}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      sx={saveButtonStyle}
                      disabled={userInfoDataForUpdate === null ? true : false}
                    >
                      Save Changes
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default UserProfile;
