import React, { useContext, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AuthContext from "../../context/AuthContext";
import {
  Box,
  Button,
  CircularProgress,
  Pagination,
  Popover,
  Switch,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Navbar from "../../Layout/Navbar";
import {
  CustomTextField,
  darkThemeStyles,
  saveButtonStyle,
} from "../../styledComponents/CustomStyleComponents";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Helmet } from "react-helmet";

const columns = [
  { label: "Medicine Suggestion Name", minWidth: 170, align: "left" },
  { label: "Status", minWidth: 100, align: "center" },
];

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontFamily: `"Lato", sans-serif`,
    backgroundColor: "#6a1b9a",
    color: darkThemeStyles.onSurface,
    padding: "10px 0 10px 16px",
    fontWeight: "bold",
    letterSpacing: "1px",
    fontSize: 15,
    borderRight: `1px solid ${darkThemeStyles.onSurface}`,
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: `"Lato", sans-serif`,
    color: darkThemeStyles.onSurface,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "7px 0 7px 16px",
    borderRight: `1px solid ${darkThemeStyles.onSurface}`,
    maxWidth: "200px",
  },
}));

const rowsPerPage = 5; // Hardcoded to 5, removed control to change it

const MedicineRecommendations = () => {
  const {
    getMedicineSuggestionsWithActiveAndDisableField,
    medicineSuggestionsChangeActiveAndInactive,
  } = useContext(AuthContext);
  const [medicineSuggestionsData, setMedicineSuggestionsData] = useState([]);
  const [medicineCount, setMedicineCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  // Add a new state to handle the filter
  const [filterType, setFilterType] = useState("active");

  const [anchorEl, setAnchorEl] = useState(null);

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const fetchDataWithoutQuery = async () => {
    setIsLoading(true);
    const { results, count } =
      await getMedicineSuggestionsWithActiveAndDisableField("", true); // by default showing active suggestions only
    setMedicineSuggestionsData(results);
    setMedicineCount(count);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDataWithoutQuery();
  }, []);

  const queryAndFilterDataCalc = async () => {
    setIsLoading(true);
    const isActive = filterType === "active";
    const isInactive = filterType === "inactive";
    const { results, count } =
      await getMedicineSuggestionsWithActiveAndDisableField(
        searchValue.trim(),
        isActive ? true : isInactive ? false : undefined
      );
    setMedicineSuggestionsData(results);
    setMedicineCount(count);
    setIsLoading(false);
  };

  const handleChangePage = (event, value) => {
    setPage(value - 1);
  };

  const handleStatusChange = (med_name) => {
    setMedicineSuggestionsData((prevData) =>
      prevData.map((data) =>
        data.med_name === med_name
          ? { ...data, is_active: !data.is_active }
          : data
      )
    );
  };

  const searchQueryHandler = async (e) => {
    e.preventDefault();
    await queryAndFilterDataCalc();
  };

  const handleApplyFilter = async () => {
    handleFilterClose();
    await queryAndFilterDataCalc();
  };

  return (
    <>
      <Helmet>
        <title>Denama | Medicine Suggestions Management</title>
      </Helmet>
      <Box
        sx={{
          height: "100%",
          bgcolor: "#121212",
        }}
      >
        <Navbar />
        <Box
          sx={{
            fontSize: "20px",
            padding: "5px",
            letterSpacing: "1px",
            borderBottom: "1px solid white",
            marginLeft: "5px",
            marginBottom: "15px",
            color: darkThemeStyles.onSurface,
          }}
        >
          Medicine Suggestions Management
        </Box>

        <Box
          component="form"
          sx={{
            display: "flex",
            py: 1,
            px: 1,
          }}
          onSubmit={searchQueryHandler}
        >
          <CustomTextField
            fullWidth
            variant="outlined"
            type="text"
            value={searchValue}
            name="searchMedicineValue"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            sx={{
              "& .MuiInputBase-input": {
                padding: "7px 14px",
                color: darkThemeStyles.onSurface,
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: 0,
              },
            }}
            placeholder="Search by Medicine Name"
          />
          <Button
            startIcon={searchValue ? <SearchIcon /> : <RefreshIcon />}
            variant="contained"
            type="submit"
            sx={{ ...saveButtonStyle, ml: 1.5 }}
          >
            {searchValue ? "Search" : "Refresh"}
          </Button>
        </Box>

        {/* Filter button */}
        <Button
          aria-describedby={id}
          variant="contained"
          size="small"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          startIcon={<FilterListIcon />}
          sx={{
            marginLeft: 2,
            marginBottom: isLoading ? 2 : 0,
            position: "relative",
            ...(filterType !== "active" && {
              "&::after": {
                content: '""',
                position: "absolute",
                top: 0,
                right: 0,
                width: 8,
                height: 8,
                borderRadius: "50%",
                backgroundColor: "red",
                transform: "translate(50%, -50%)",
              },
            }),
          }}
        >
          Add Filter
        </Button>

        {/* Filter Popover */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{ marginTop: 1 }}
        >
          <Box p={2}>
            <FormControl>
              <FormLabel
                id="status-row-radio-buttons-group-label"
                sx={{
                  color: darkThemeStyles.surface,
                }}
              >
                Status
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="status-row-radio-buttons-group-label"
                name="status-row-radio-buttons-group"
                value={filterType}
                onChange={(event) => setFilterType(event.target.value)}
              >
                <FormControlLabel
                  value="active"
                  control={<Radio />}
                  label="Active"
                />
                <FormControlLabel
                  value="inactive"
                  control={<Radio />}
                  label="Inactive"
                />
                <FormControlLabel
                  value="both"
                  control={<Radio />}
                  label="Both"
                />
              </RadioGroup>
            </FormControl>

            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button onClick={handleFilterClose}>Cancel</Button>
              <Button onClick={handleApplyFilter} variant="contained">
                Apply
              </Button>
            </Box>
          </Box>
        </Popover>

        {!isLoading && medicineSuggestionsData.length > 0 && (
          <>
            <Box
              textAlign="center"
              color={darkThemeStyles.onSurface}
              pb="10px"
              fontSize="20px"
              fontWeight={600}
              fontFamily={`"Lato", sans-serif`}
            >
              {medicineCount} Hits
            </Box>
          </>
        )}

        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            position: "relative",
            bgcolor: `${darkThemeStyles.surface}`,
          }}
        >
          {isLoading ? ( // Check if loading
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              minHeight={400}
            >
              <Typography
                variant="h5"
                sx={{ color: darkThemeStyles.onSurface, pb: 2 }}
              >
                Searching
              </Typography>
              <CircularProgress /> {/* Loading indicator */}
            </Box>
          ) : medicineSuggestionsData.length > 0 ? (
            <>
              <TableContainer sx={{ maxHeight: 430 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table for medicine recommendation"
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.label}
                          align={column.align}
                          sx={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {medicineSuggestionsData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow tabIndex={-1} key={index}>
                          <StyledTableCell>{row.med_name}</StyledTableCell>
                          <StyledTableCell align="center">
                            <Switch
                              checked={row.is_active}
                              onChange={() => handleStatusChange(row.med_name)}
                              inputProps={{
                                "aria-label": row.is_active
                                  ? "Deactivate"
                                  : "Activate",
                              }}
                              color={row.is_active ? "success" : "warning"}
                            />

                            <Typography
                              sx={{
                                color: row.is_active ? "#4caf50" : "#ff9800",
                                fontWeight: "bold",
                                fontSize: 14,
                              }}
                            >
                              {row.is_active ? "Active" : "Inactive"}
                            </Typography>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box display="flex" justifyContent="center" my={1}>
                <Pagination
                  count={Math.ceil(
                    medicineSuggestionsData.length / rowsPerPage
                  )}
                  page={page + 1}
                  onChange={handleChangePage}
                  color="primary"
                  sx={{
                    ".MuiPaginationItem-root": {
                      color: "white", // or any other color
                    },
                  }}
                />
              </Box>
              <Box sx={{ textAlign: "right", pb: 2, pr: 2 }}>
                <Button
                  variant="contained"
                  onClick={() =>
                    medicineSuggestionsChangeActiveAndInactive(
                      medicineSuggestionsData
                    )
                  }
                  sx={saveButtonStyle}
                >
                  Save Changes
                </Button>
              </Box>
            </>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight={400}
              flexDirection="column"
            >
              <SentimentDissatisfiedIcon
                sx={{ fontSize: 60, color: darkThemeStyles.primary }}
              />
              <Typography
                variant="h6"
                sx={{ color: darkThemeStyles.onSurface, my: 2 }}
              >
                No results match your search criteria
              </Typography>
            </Box>
          )}
        </Paper>
      </Box>
    </>
  );
};

export default MedicineRecommendations;
