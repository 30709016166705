import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Grid, ThemeProvider, Tooltip } from "@mui/material";
import styled from "@emotion/styled";
import Autocomplete from "@mui/material/Autocomplete";
import AuthContext from "../../context/AuthContext";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  CustomTextField,
  darkTheme,
  darkThemeStyles,
} from "../../styledComponents/CustomStyleComponents";
import { debounce } from "lodash";

const StyledGridItem = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "none",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  flexDirection: "column",
}));

const SmallActionGridItem = styled(StyledGridItem)({
  width: "65px", // Or whatever fixed width you prefer
});

const HeaderGridItem = styled(Grid)(() => ({
  backgroundColor: "#264768",
  border: "1px solid #adb5bd",
  padding: "8px",
  fontWeight: "bold",
  textAlign: "center",
}));

const SmallHeaderGridItem = styled(HeaderGridItem)({
  width: "65px",
  padding: "8px 0",
});

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiAutocomplete-inputRoot": {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  "& .MuiAutocomplete-listbox": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
}));

const TreatmentAndRecommendations = () => {
  const {
    setMediList,
    mediList,
    selectedVisitAnalysisAndMedicineData,
    getMedicineSuggestionsForPatients,
  } = useContext(AuthContext);

  // Ref for the first input of the last row
  const lastRowFirstInputRef = useRef(null);
  // State to track if a new row has been added
  const [rowAdded, setRowAdded] = useState(false);

  // New state to track input values and suggestions for each row
  const [autocompleteData, setAutocompleteData] = useState([]);

  const isDisabled = !!selectedVisitAnalysisAndMedicineData;

  const handleAddBtn = () => {
    if (!selectedVisitAnalysisAndMedicineData) {
      setMediList((prevMediList) => [
        ...prevMediList,
        {
          med_name: "",
          med_schedule: "",
          med_instructions: "",
          med_route: "",
          number_of_days: 0,
        },
      ]);
      // Indicate that a new row has been added
      setRowAdded(true);
    }
  };

  const fetchMedicineSuggestions = async (value, index) => {
    if (value.length > 2) {
      // Fetch logic
      const reqData = await getMedicineSuggestionsForPatients(value);
      setAutocompleteData((currentData) =>
        currentData.map((item, idx) =>
          idx === index ? { ...item, suggestions: reqData } : item
        )
      );
    } else {
      setAutocompleteData((currentData) =>
        currentData.map((item, idx) =>
          idx === index ? { ...item, suggestions: [] } : item
        )
      );
    }
  };

  const debouncedFetchMedicineSuggestions = useMemo(
    () =>
      autocompleteData.map((_, index) =>
        debounce((value) => fetchMedicineSuggestions(value, index), 300)
      ),
    // Depend on autocompleteData.length to regenerate the debounced functions when the number of items changes
    [autocompleteData.length]
  );

  const handleFieldChange = (field, index, newValue) => {
    setMediList((prevMediList) => {
      const updatedMediList = [...prevMediList];
      updatedMediList[index][field] = newValue;
      return updatedMediList;
    });

    // Ensure index is within the bounds of the debouncedFetchMedicineSuggestions array
    if (index >= 0 && index < debouncedFetchMedicineSuggestions.length) {
      !isDisabled && debouncedFetchMedicineSuggestions[index](newValue);
    }
  };

  const removeMediHandler = (index) => {
    setMediList((prevMediList) => {
      const updatedMediList = [...prevMediList];
      updatedMediList.splice(index, 1);
      return updatedMediList;
    });
  };

  useEffect(() => {
    let medicine = selectedVisitAnalysisAndMedicineData?.medicine;
    if (medicine) {
      setMediList(medicine);
    } else {
      setMediList([
        {
          med_name: "",
          med_schedule: "",
          med_instructions: "",
          med_route: "",
          number_of_days: 0,
        },
      ]);
    }
  }, [selectedVisitAnalysisAndMedicineData]);

  // Focus on the first input field of the last row only when a new row is added
  useEffect(() => {
    if (rowAdded && lastRowFirstInputRef.current) {
      lastRowFirstInputRef.current.focus();
      // Reset rowAdded to false after focusing
      setRowAdded(false);
    }

    // Sync autocompleteData with mediList from the context
    setAutocompleteData((currentAutocompleteData) => {
      // Create an array with the same length as mediList
      const updatedAutocompleteData = new Array(mediList.length);

      // Fill the array with existing data or an empty object for new rows
      for (let i = 0; i < mediList.length; i++) {
        updatedAutocompleteData[i] = currentAutocompleteData[i] || {
          suggestions: [],
        };
      }
      return updatedAutocompleteData;
    });
  }, [mediList, rowAdded]); // Depend on rowAdded to control the focus logic

  return (
    <section>
      <div
        className="p-heading"
        style={{
          height: "30px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>Treatment and Recommendations</span>
        {!selectedVisitAnalysisAndMedicineData && (
          <>
            <span>
              <Button
                aria-label="add-medicine"
                variant="contained"
                sx={{
                  padding: "1px 15px",
                  textTransform: "none",
                  backgroundColor: "#8772b9",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#8772b9",
                  },
                }}
                startIcon={<AddCircleOutlineIcon />}
                onClick={handleAddBtn}
              >
                Add Medicine
              </Button>
            </span>
          </>
        )}
      </div>
      <Box sx={{ padding: "5px" }}>
        <Grid container spacing={0}>
          {!selectedVisitAnalysisAndMedicineData && (
            <SmallHeaderGridItem item>Action</SmallHeaderGridItem>
          )}
          <HeaderGridItem item xs>
            Medicine Prescription
            <Tooltip title="Required" placement="top">
              <span style={{ color: "red" }}>&nbsp;*</span>
            </Tooltip>
          </HeaderGridItem>
          {!selectedVisitAnalysisAndMedicineData && (
            <SmallHeaderGridItem item>Action</SmallHeaderGridItem>
          )}
        </Grid>

        <Box
          sx={{
            maxHeight: "300px",
            overflow: "auto",
          }}
        >
          {mediList.map((item, index) => (
            <Grid container key={index} spacing={0}>
              {!selectedVisitAnalysisAndMedicineData && (
                <SmallActionGridItem item>
                  <Button
                    aria-label="remove-medicine-btn"
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      backgroundColor: "#e5383b",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#e5383b",
                      },
                      padding: "7px 22px",
                    }}
                    onClick={removeMediHandler.bind(null, index)}
                    disabled={isDisabled}
                  >
                    <HighlightOffIcon />
                  </Button>
                </SmallActionGridItem>
              )}
              <StyledGridItem item xs>
                <ThemeProvider theme={darkTheme}>
                  <StyledAutocomplete
                    freeSolo
                    options={autocompleteData[index]?.suggestions || []}
                    value={item?.med_name || ""}
                    // onChange of Autocomplete gets an event and the new value as arguments
                    onInputChange={(event, newValue) =>
                      handleFieldChange("med_name", index, newValue)
                    }
                    disabled={isDisabled} // Disable if selectedVisitAnalysisAndMedicineData has a value
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: 0,
                            backgroundColor: darkThemeStyles.surface,
                            borderRadius: 0,
                          },
                        }}
                        placeholder={isDisabled ? "" : "Type medicine's name"}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !isDisabled) {
                            e.preventDefault(); // Prevent the default action to avoid form submission if this is within a form
                            handleAddBtn();
                          }
                        }}
                        // Only set the ref on the first input of the last row if rowAdded is true
                        inputRef={
                          rowAdded && index === mediList.length - 1
                            ? lastRowFirstInputRef
                            : null
                        }
                      />
                    )}
                    fullWidth
                  />
                </ThemeProvider>
              </StyledGridItem>

              {!selectedVisitAnalysisAndMedicineData && (
                <SmallActionGridItem item>
                  <Button
                    aria-label="add-medicine-btn"
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      backgroundColor: "#52b788",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#52b788",
                      },
                      padding: "7px 22px",
                    }}
                    onClick={handleAddBtn}
                    disabled={isDisabled}
                  >
                    <AddCircleOutlineIcon />
                  </Button>
                </SmallActionGridItem>
              )}
            </Grid>
          ))}
        </Box>
      </Box>
    </section>
  );
};

export default TreatmentAndRecommendations;
