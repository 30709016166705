import card_1 from "../../assets/images/pic1.jpg";
import card_2 from "../../assets/images/pic2.jpg";
import card_3 from "../../assets/images/pic3.jpg";
import card_4 from "../../assets/images/pic4.jpg";
export const whyDenamaCardsData = [
  {
    title: "User-Friendly Interface",
    content:
      "Experience the Ease of Denama: Tailor-Made for Medical Practices to Streamline Operations, Simplify Patient Interactions, and Organize Records with Intuitive Precision.",
    img: card_1,
  },
  {
    title: "Personalized Patient Care",
    content:
      "Denama healthcare providers with deep insights into patient profiles, crafting personalized care pathways and informed choices. Elevate patient-doctor rapport and the quality of healthcare with our tailored solutions.",
    img: card_2,
  },
  {
    title: "Secure and Compliant",
    content:
      "Denama stands guard with robust security and compliance measures, safeguarding patient data with cutting-edge protocols. Ensure peace of mind with our commitment to privacy and regulatory adherence.",
    img: card_3,
  },
  {
    title: "Integrated Communication Hub",
    content:
      "Our CRM transforms into your Integrated Communication Nexus, streamlining dialogue among healthcare teams and patients. Denama enables swift, effective exchanges, fostering a unified and agile care setting.",
    img: card_4,
  },
];
