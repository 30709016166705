import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Button } from "@mui/material";
import AuthContext from "../../context/AuthContext";
import { saveButtonStyle } from "../../styledComponents/CustomStyleComponents";

const autoResize = (e) => {
  e.target.style.height = "inherit";
  const scrollHeight = e.target.scrollHeight;
  if (scrollHeight < 250) {
    e.target.style.height = `${scrollHeight}px`;
  } else {
    e.target.style.height = `250px`;
    e.target.style.overflowY = "auto";
  }
};

const InputBoxForNote = () => {
  const { setNotification, notePostData } = useContext(AuthContext);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    if (title === "") {
      setNotification({
        isOpen: true,
        message: "Please Add Title for making a note.",
        severity: "warning",
      });
      return;
    }
    if (content === "") {
      setNotification({
        isOpen: true,
        message: "Please Add Content for making a note.",
        severity: "warning",
      });
      return;
    }
    notePostData({
      title,
      content,
    });
    setTitle("");
    setContent("");
  };

  return (
    <>
      <Box sx={{ width: "100%", padding: "10px" }}>
        <form onSubmit={submitHandler}>
          <Card
            variant="outlined"
            sx={{
              height: "100%",
              border: "0.2px solid black",
              borderRadius: "8px",
            }}
          >
            <CardContent sx={{ "&:last-child": { paddingBottom: "5px" } }}>
              <input
                type="text"
                autoComplete="off"
                placeholder="Title"
                name="notesTitle"
                className="notes-title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <textarea
                type="text"
                name="notesContent"
                placeholder="Take a note..."
                autoComplete="off"
                className="notes-content"
                onInput={autoResize}
                style={{ overflow: "hidden" }}
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  type="submit"
                  aria-label="add-note"
                  sx={saveButtonStyle}
                >
                  Add Note
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </form>
      </Box>
    </>
  );
};

export default InputBoxForNote;
