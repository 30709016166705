import React from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { convertToTitleCaseWithSpace } from "../../context/contextUtils";

const styles = StyleSheet.create({
  table: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#f0f0f0",
    padding: "10px 35px",
  },
  row: {
    flexDirection: "row",
    alignItems: "baseline",
    marginBottom: "5px",
  },
  label: {
    fontSize: 10,
    fontFamily: "Lato Bold",
    marginRight: 2,
  },
  value: {
    fontSize: 10,
  },
});

const ageDisplay = (year, mnth, day) => {
  if (+year) {
    return `${+year} Years`;
  } else if (+mnth) {
    return `${+mnth} Months`;
  } else if (+day) {
    return `${+day} Days`;
  } else {
    return "";
  }
};

const PersonalDetails = ({ personalDetails, visitDate }) => {
  return (
    <View style={styles.table}>
      <View>
        <View style={styles.row}>
          <Text style={styles.label}>Name: </Text>
          <Text style={styles.value}>
            {convertToTitleCaseWithSpace(personalDetails.name) || ""}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Age: </Text>
          <Text style={styles.value}>
            {ageDisplay(
              personalDetails.age_in_years,
              personalDetails.age_in_months,
              personalDetails.age_in_days
            )}
          </Text>
        </View>
      </View>
      <View>
        <View style={styles.row}>
          <Text style={styles.label}>Visit Date: </Text>
          <Text style={styles.value}>{visitDate?.split(" ")[0] || ""}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.label}>Gender: </Text>
          <Text style={styles.value}>{personalDetails.gender || ""}</Text>
        </View>
      </View>
    </View>
  );
};

export default PersonalDetails;
