import Home from "../pages/home/Home";
import Patient from "../pages/patient/Patient";
import Notes from "../pages/notes/Notes";
import UserProfile from "../pages/user/UserProfile";
import Dashboard from "../pages/dashboard/Dashboard";
import PrescriptionPdfRender from "../pages/prescriptionPdf/PrescriptionPdfRender";
import SupportPanel from "../pages/support/SupportPanel";
import ChangePassword from "../pages/user/ChangePassword";
import MedicineRecommendations from "../pages/medicineSuggestionsManagement/MedicineRecommendations";

const privateRoutes = [
  {
    path: "/dashboard",
    element: <Home />,
    children: [
      {
        path: "", // Note the absence of the preceding '/'
        element: <Dashboard />,
      },
      {
        path: "my-profile",
        element: <UserProfile />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
      {
        path: "support",
        element: <SupportPanel />,
      },
    ],
  },
  {
    path: "/patient",
    element: <Patient />,
  },
  {
    path: "/medicine-suggestions",
    element: <MedicineRecommendations />,
  },
  {
    path: "/notes",
    element: <Notes />,
  },
  {
    path: "/prescription-download/:patientId/:visitId",
    element: <PrescriptionPdfRender />,
  },
];

export default privateRoutes;
