import React, { useContext, useEffect, useRef, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import AuthContext from "../../context/AuthContext";
import { Box, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const StyledTableCell = styled(TableCell)(() => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: "white",
  fontSize: 14,
  backgroundColor: "#193549",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  "&.Mui-selected": {
    backgroundColor: "rgb(111 116 167 / 53%)",
  },
  "&:hover": {
    backgroundColor: "rgb(255 255 255 / 47%)", // Change as per your color scheme
  },
  cursor: "pointer",
  padding: "3.5px 20% 3.5px 2%",
}));

const SearchResultsTable = ({ searchResults, searchField }) => {
  const {
    setSelectedPatientRowId,
    selectedPatientRowId,
    fetchPatientDataUsingPatientId,
    setSelectedPatientAndAllVisitsData,
    setSelectedVisitAnalysisAndMedicineData,
    setIsrevisitBtnClicked,
  } = useContext(AuthContext);

  // New state to keep track of the currently focused row
  const [focusedRow, setFocusedRow] = useState(null);
  // Using a ref to keep track of the container for handling focus within it
  const resultsContainerRef = useRef(null);

  useEffect(() => {
    setFocusedRow(null); // Set to null if there are no search results
  }, [searchResults]);

  const handleRowClick = (patientId, rowIndex) => {
    if (selectedPatientRowId === patientId) {
      setSelectedPatientRowId(null);
      setSelectedPatientAndAllVisitsData(null);
      setSelectedVisitAnalysisAndMedicineData(null);
    } else {
      setSelectedPatientRowId(patientId);
      setIsrevisitBtnClicked(false);
      fetchPatientDataUsingPatientId(patientId);

      // Scroll the clicked or navigated row into view
      document.getElementById(`row-${rowIndex}`)?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      let newFocusedRow = focusedRow;

      if (e.key === "ArrowDown") {
        e.preventDefault(); // Prevent page scrolling
        newFocusedRow =
          focusedRow !== null
            ? Math.min(focusedRow + 1, searchResults.length - 1)
            : 0;
      } else if (e.key === "ArrowUp") {
        e.preventDefault(); // Prevent page scrolling
        newFocusedRow =
          focusedRow !== null
            ? Math.max(focusedRow - 1, 0)
            : searchResults.length - 1;
      }

      if (newFocusedRow !== focusedRow) {
        setFocusedRow(newFocusedRow);

        // Call handleRowClick with the patientId and index of the new focused row
        const patientId = searchResults[newFocusedRow].patient_id;
        handleRowClick(patientId, newFocusedRow);

        // Ensure the newly focused row comes into view
        document.getElementById(`row-${newFocusedRow}`)?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    };

    const currentResultsContainer = resultsContainerRef.current;
    currentResultsContainer?.addEventListener("keydown", handleKeyDown);

    return () => {
      currentResultsContainer?.removeEventListener("keydown", handleKeyDown);
    };
  }, [focusedRow, searchResults.length]);

  return (
    <Box
      ref={resultsContainerRef}
      sx={{
        maxHeight: "140px",
        overflowY: "auto",
        "&:focus": {
          outline: "none",
        },
      }}
      tabIndex={0} // Make the Box focusable
    >
      {searchResults.length > 0 ? (
        searchResults.map((value, index) => (
          <StyledTableCell
            key={value.patient_id}
            id={`row-${index}`}
            component="div"
            className={focusedRow === index ? "Mui-selected" : ""}
            onClick={() => {
              setFocusedRow(index); // Set focus to this row
              handleRowClick(value.patient_id, index); // Handle selection and ensure the row is scrolled into view
            }}
          >
            <div style={{ flex: 1, textAlign: "left" }}>{value["name"]}</div>
            {searchField !== "name" && (
              <div style={{ flex: 1, textAlign: "left", paddingLeft: "10px" }}>
                {value[searchField]}
              </div>
            )}
          </StyledTableCell>
        ))
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "9px",
            backgroundColor: "#292929",
          }}
        >
          <SearchIcon sx={{ fontSize: 64, color: "#E0E0E0" }} />
          <Typography variant="subtitle1" textAlign="center" color="#E0E0E0">
            Enter Patient's {searchField} to find patient data.
          </Typography>

          <Typography variant="body2" sx={{ mt: 1, color: "#E0E0E0" }}>
            Try searching by name, address, or mobile number.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SearchResultsTable;
