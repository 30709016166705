import React, { useContext, useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import styled from "@emotion/styled";
import {
  Card,
  TableHead,
  Chip,
  IconButton,
  Tooltip,
  TablePagination,
} from "@mui/material";
import "./SupportPanel.css";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import AuthContext from "../../context/AuthContext";
import { darkThemeStyles } from "../../styledComponents/CustomStyleComponents";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(145, 158, 171, 0.12)",
    letterSpacing: 1,
    fontWeight: "600",
    fontFamily: "inherit",
    flex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    color: "white",
    border: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: 1.57143,
    fontFamily: "inherit",
    flex: 1,
  },
  fontSize: "1rem",
  color: "white",
  border: "none",
}));

const StyledTableFooter = styled(TableFooter)(() => ({
  color: "white",
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  height: "35px",
}));

const SupportTicketsTableHeader = [
  "ID",
  "Summary",
  "Status",
  "Priority",
  "Created At",
  "Actions",
];

const StyledTablePagination = styled(TablePagination)`
  .MuiTablePagination-toolbar,
  .MuiTablePagination-caption,
  .MuiTablePagination-selectLabel,
  .MuiTablePagination-select,
  .MuiTablePagination-actions {
    color: white; // Replace 'blue' with your desired color
  }
`;

const SupportTicketsTable = () => {
  const tableContainerRef = useRef(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);

  const {
    supportTicketsData,
    supportTicketDeleteAPI,
    fetchSupportTicketsData,
    fetchUserInfo,
    userInfo,
  } = useContext(AuthContext);
  const [rows, setRows] = useState(
    supportTicketsData?.sort((a, b) => b.id - a.id)
  );

  useEffect(() => {
    if (!userInfo) {
      fetchUserInfo();
    } else {
      fetchSupportTicketsData();
    }
  }, [userInfo]);

  useEffect(() => {
    setRows(supportTicketsData);
  }, [supportTicketsData]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderPaginationLabel = ({ from, to, count }) => {
    return rows?.length > 0
      ? `${page + 1} of ${parseInt(Math.ceil(count / rowsPerPage))}`
      : "";
  };

  const backgroundColorForStatusCol = (value) => {
    if (value === "To do") {
      return "#FD8D14";
    } else if (value === "In Progress") {
      return "green";
    } else if (value === "Cancelled") {
      return "#f71d1d";
    } else if (value === "Done") {
      return "blue";
    } else {
      return "gray"; // for blocked
    }
  };

  const ticketDeleteHandler = async (id) => {
    await supportTicketDeleteAPI(id);
    await fetchSupportTicketsData();
  };

  useEffect(() => {
    if (tableContainerRef.current) {
      const tableHeight = tableContainerRef.current.clientHeight;
      const singleRowHeight = 75;
      const dynamicRowsPerPage = Math.floor(tableHeight / singleRowHeight);
      setRowsPerPage(dynamicRowsPerPage);
    }
  }, [tableContainerRef]);

  return (
    <Card
      sx={{
        flex: 1, // This allows the card to grow and take up remaining space
        borderRadius: 0,
        width: "100%",
        backgroundColor: darkThemeStyles.surface,
        display: "flex", // Make the Card a flex container
        flexDirection: "column", // Make the direction column
        p: 1,
      }}
    >
      <>
        <TableContainer
          sx={{ flex: 1, overflow: "auto", width: "100%" }}
          ref={tableContainerRef}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
            }}
          >
            <TableHead>
              <TableRow sx={{ display: "flex" }}>
                {SupportTicketsTableHeader?.map((columns) => (
                  <StyledTableCell key={columns} align="center">
                    {columns}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsPerPage > 0 &&
                (rowsPerPage > 0
                  ? rows?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                )?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.25)",
                      },
                      display: "flex",
                    }}
                  >
                    <StyledTableCell align="center">#{row.id}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.summary}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Chip
                        label={row.status}
                        sx={{
                          backgroundColor: backgroundColorForStatusCol(
                            row.status
                          ),
                          color: "white",
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.priority}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.date_created}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Tooltip title="Delete" placement="top">
                        <IconButton
                          sx={{ color: "red" }}
                          onClick={ticketDeleteHandler.bind(null, row.id)}
                        >
                          <DeleteOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </TableRow>
                ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <StyledTableCell colSpan={SupportTicketsTableHeader.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {rows?.length && (
          <TableContainer>
            <Table sx={{ overflow: "hidden", borderTop: "0.1px solid white" }}>
              <StyledTableFooter>
                <TableRow>
                  <TableCell colSpan={SupportTicketsTableHeader.length}>
                    {rows?.length && (
                      <StyledTablePagination
                        component="div"
                        rowsPerPageOptions={[]} // Remove the rows per page options
                        count={rows?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            "aria-label": "rows per page",
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={renderPaginationLabel}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </StyledTableFooter>
            </Table>
          </TableContainer>
        )}
      </>
    </Card>
  );
};

export default SupportTicketsTable;
